import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import { DAYS, MONTHS, PERIOD_DROPDOWN } from '../../config/constants';
import SectionDivider from '../common/section-divider';
import SelectComponent from '../common/select2';
import Register from '../navigation/Register';
import { ConnectorAPIService, OrganizationAPIService } from '../../config/api-service';
import momentTZ from 'moment-timezone';

function ConfigureScheduleComponent({ pipelineId, pipelineSchedule, setPipelineSchedule }) {
    const [scheduleId, setScheduleId] = useState();
    const [runPeriod, setRunPeriod] = useState(1);
    const [minute, setMinute] = useState(0);
    const [time, setTime] = useState('');
    const [weekDay, setWeekDay] = useState('');
    const [monthDay, setMonthDay] = useState('');
    const [month, setMonth] = useState(''); 
    const [hourOffset, setHourOffset] = useState('');
    const [timezone, setTimezone] = useState('');

    const [openRegisterModal, setOpenRegisterModal] = useState(false);
  
    useEffect(() => {
        setPipelineSchedule({
            scheduleEnabled: true,
            scheduleId,
            period: runPeriod,
            minute,
            time,
            weekDay,
            monthDay,
            month,
            hourOffset,
        });
    }, [scheduleId, runPeriod, minute, time, weekDay, monthDay, month, hourOffset]);

    useEffect(() => {
        const getPipelineDetails = async () => {
            const pipeline = await ConnectorAPIService.getPipelineDetails(pipelineId);
            setScheduleId(Number(pipeline.job.schedule_id));
            setRunPeriod(Number(pipeline.job.period_selection_txt));
            setMinute(Number(pipeline.job.minute_selection_txt));
            setTime(pipeline.job.time_selection_txt);
            setWeekDay(pipeline.job.week_day_selection_txt);
            setMonthDay(pipeline.job.month_day_selection_txt);
            setMonth(pipeline.job.month_selection_txt);
        }

        const getOrganizationTimezone = async () => {
            const {organization} = await OrganizationAPIService.getOrganizationDetail();
            setTimezone(organization?.TimezoneTXT || 'America/New_York');
            let hourOffset = momentTZ(new Date()).tz(organization?.TimezoneTXT || 'America/New_York').utcOffset() / 60
            setHourOffset(hourOffset);
        }
        getPipelineDetails();
        getOrganizationTimezone();
    }, []);

    const getTimePeriod = (runPeriod) => {
        switch (runPeriod) {
            case 0: // Hourly Selection
                return (
                    <Grid item xs={6}>
                        {minuteField()}
                    </Grid>
                )
            case 1: // Daily Selection
                return (
                    <Grid item xs={6}>
                        {timeField()}
                    </Grid>
                )
            case 2: // Every Weekday Selection
                return (
                    <Grid item xs={6}>
                        {timeField()}
                    </Grid>
                )
            case 3: // Weekly Selection
                return (<>
                    <Grid item xs={6}>
                        {weekdayField()}
                    </Grid>
                    <Grid item xs={6}>
                        {timeField()}
                    </Grid>
                </>)
            case 4: // Monthly Selection
                return (<>
                    <Grid item xs={6}>
                        {monthDayField()}
                    </Grid>
                    <Grid item xs={6}>
                        {timeField()}
                    </Grid>
                </>)
            case 5: // Yearly Selection
                return (<>
                    <Grid item xs={4}>
                        {monthField()}
                    </Grid>
                    <Grid item xs={4}>
                        {monthDayField()}
                    </Grid>
                    <Grid item xs={4}>
                        {timeField()}
                    </Grid>
                </>)
            // case 6: // Manual Selection
            //     return (<>
            //         <Grid item xs={6}>
            //             {manualField()}
            //         </Grid>
            //     </>)
            default:
                throw new Error('Invalid period. Use 0-5');
        }
    }

    const minuteField = () => {
        return (<>
            <TextField
                variant="outlined"
                size="small"
                type="number"
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
                fullWidth
                label="Minute"
            />
        </>)
    }

    const timeField = () => {
        return (<>
            <TextField
                variant="outlined"
                size="small"
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                fullWidth
                label="Time"
            />
        </>)
    }

    const weekdayField = () => {
        return (<>
            <SelectComponent
                value={weekDay}
                setValue={setWeekDay}
                items={DAYS}
                label="Weekday"
            />
        </>)
    }

    const monthField = () => {
        return (<>
            <SelectComponent
                value={month}
                setValue={setMonth}
                items={MONTHS}
                InputProps={{ inputProps: { min: 1, max: 12 } }}
                label="Month"
            />
        </>)
    }

    const monthDayField = () => {
        return (<>
            <TextField
                variant="outlined"
                size="small"
                type="number"
                value={monthDay}
                InputProps={{ inputProps: { min: 1, max: 31 } }}
                onChange={(e) => setMonthDay(e.target.value)}
                fullWidth
                label="Day of Month"
            />
        </>)
    }

    const manualField = () => {
        return (<>
            <TextField
                variant="outlined"
                size="small"
                type="number"
                defaultValue={1}
                // value={monthDay}
                // onChange={(e) => setMonthDay(e.target.value)}
                InputProps={{ inputProps: { min: 1, max: 31 } }}
                fullWidth
                label="Manual"
            />
        </>)
    }

    return (
        <>
            <SectionDivider label="Pipeline Run Schedule">
                <Grid container spacing={1} style={{ marginBottom: 3 }}>
                    <Grid item xs={6}>
                        <SelectComponent
                            value={runPeriod}
                            setValue={setRunPeriod}
                            items={PERIOD_DROPDOWN}
                            label="Period"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="To edit the timezone you must update the timezone in the Organization settings page">
                            <div>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={timezone}
                                    fullWidth
                                    label="Timezone"
                                />
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {getTimePeriod(runPeriod)}
                        </Grid>
                    </Grid>
                </Grid>
                {
                    openRegisterModal && <Register openRegisterModal={openRegisterModal} setOpenRegisterModal={setOpenRegisterModal} />
                }
            </SectionDivider>
            <div>
                Are you sure you want to enable this pipeline?
            </div>
        </>
    )
}


const DisablePipelineContent = ({ pipelineDialog }) => {
    return (
        <div>
            Are you sure you want to disable this pipeline?
        </div>
    );
};

const EnablePipelineDialogContent = ({ pipelineDialog, pipelineSchedule, setPipelineSchedule }) => {
    if (pipelineDialog?.status) {
        return <ConfigureScheduleComponent pipelineId={pipelineDialog?.id} pipelineSchedule={pipelineSchedule} setPipelineSchedule={setPipelineSchedule} />;
    } else {
        return <DisablePipelineContent />;
    }
};

EnablePipelineDialogContent.propTypes = {
    pipelineDialog: PropTypes.shape({
        status: PropTypes.bool
    })
};

export default EnablePipelineDialogContent;
