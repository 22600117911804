import { useEffect, useState, useMemo } from "react";
import { CommonSelect } from "../common/form/Select";
import { TextField, Box, Button, CircularProgress, Tooltip } from "@mui/material";
import { Check, Close } from '@mui/icons-material';
import { DataConCheckbox } from "../common/data-con-form/FormFields";
import { useForm, useWatch } from "react-hook-form";
import OauthPopup from "./OAuthPopup";
import { AuthenticationField } from "./AuthenticationField";
import { ConnectorAPIService } from "../../config/api-service";

const getAuthenticationTypes = (authenticationMethods) => {
    if (Array.isArray(authenticationMethods)) {
        return authenticationMethods.map(am => ({ authentication_type_id: am.authentication_type_id, authentication_type_name: am.authentication_type_name }));
    } else {
        delete authenticationMethods.fields;
        return authenticationMethods
    }
}

const getAuthenticationFields = (authenticationMethod) => {
    return authenticationMethod.fields.sort((a, b) => a.field_scope_id - b.field_scope_id);
}

const getAuthenticationConfig = (authenticationMethod) => {
    return Object.hasOwn(authenticationMethod, "config") ? authenticationMethod.config : null;
}

const getDefaultValues = (authenticationFields) => {
    let defaultValues = authenticationFields.reduce((acc, field) => {
        acc[field.field_nm] = field.default_value_txt || "";
        if (field.field_data_type_txt === "checkbox") {
            acc[field.field_nm] = field.default_value_txt === "1" ? true : false;
        }
        if (field.field_data_type_txt === "select") {
            acc[field.field_nm] = field.allowed_values_txt.split(",")[0];
        }
        if (field.field_data_type_txt === "radio") {
            acc[field.field_nm] = field.allowed_values_txt.split(",")[0];
        }
        
        return acc;
    }, {});
    return defaultValues;
}


export function AuthenticationType({ authenticationMethods, app, saveConnection, mode="user", nextUserId }) {
    const connectorName = useMemo(() => app.ConnectorNM, [app]);
    const [authenticationFields, setAuthenticationFields] = useState(getAuthenticationFields(authenticationMethods[0]));
    const [authenticationTypes, setAuthenticationTypes] = useState(getAuthenticationTypes(authenticationMethods));
    const [selectedAuthenticationTypeId, setSelectedAuthenticationTypeId] = useState(authenticationMethods[0].authentication_type_id);
    const [connectionState, setConnectionState] = useState({state:"idle", message:""});
    const [config, setConfig] = useState(getAuthenticationConfig(authenticationMethods[0]));
    const [connectorCredentialId, setConnectorCredentialId] = useState(null);

    const defaultValues = useMemo(() => ({
        defaultAccount: false,
        mode: mode,
        connectionName: `${connectorName} ${nextUserId ? ` ${nextUserId}` : ""}`,
        ...getDefaultValues(authenticationFields)
    }), [authenticationFields, connectorName, nextUserId]);


    const { handleSubmit, control, reset, formState: { isValid } } = useForm({
        mode: 'all',
        defaultValues
    })

    const formValues = useWatch({ control });

    useEffect(() => {
        console.log(formValues)
        setConfig((prev) => ({ ...prev, ...formValues }));
    }, [formValues]);

    useEffect(() => {
        if (authenticationMethods && authenticationMethods.length) {
            setAuthenticationTypes(getAuthenticationTypes(authenticationMethods));
            setAuthenticationFields(getAuthenticationFields(authenticationMethods[0]));
        }
    }, [authenticationMethods])

    const changeAuthenticationType = (e) => {
        const authenticationMethod = authenticationMethods.find(am => am.authentication_type_id === e.target.value);
        setSelectedAuthenticationTypeId(e.target.value);
        setAuthenticationFields(getAuthenticationFields(authenticationMethod));
        setConnectionState({state:"idle", message:""});
        setConfig(getAuthenticationConfig(authenticationMethod));
    }

    const handleTestConnection = (values) => {
        if (selectedAuthenticationTypeId === '1') {
            if (values.code) {
                setConnectionState({state:"loading", message:""});
                ConnectorAPIService.testConnector(app.ConnectorID, {
                    credentials: {
                        ...formValues, 
                        code: values.code,
                        redirect_uri: values.redirect_uri,
                        defaultAccount: undefined, 
                        mode: undefined, 
                        connectionName: undefined
                    }, 
                    connectionName: formValues.connectionName,
                    defaultFlg: formValues.defaultAccount,
                    connectorTestFlg: mode === "test",
                    authenticationTypeId: selectedAuthenticationTypeId,
                    connectorCredentialId: connectorCredentialId
                }).then((res) => {
                    if (res.results.success) {
                        setConnectorCredentialId(res.results.connectorCredentialId);
                        setConnectionState({state:"connected", message:"Connection successful"});
                    } else {
                        setConnectionState({state:"failed", message:"Failed to test connection."});
                    }
                }).catch((err) => {
                    setConnectionState({state:"failed", message:"Failed to test connection."});
                });
            } else {
                setConnectionState({state:"failed", message:"Failed to authenticate login."});
            }

        } else {
            console.log(values);
            setConnectionState({state:"loading", message:""});

            ConnectorAPIService.testConnector(app.ConnectorID, {
                credentials: {
                    ...values,
                    defaultAccount: undefined,
                    mode: undefined,
                    connectionName: undefined
                }, 
                authenticationTypeId: selectedAuthenticationTypeId, 
                connectorCredentialId: connectorCredentialId,
                connectionName: formValues.connectionName,
                defaultFlg: values.defaultAccount,
                connectorTestFlg: mode === "test"
            }).then((res) => {
                if (res.results.success) {
                    setConnectorCredentialId(res.results.connectorCredentialId);
                    setConnectionState({state:"connected", message:"Connection successful"});
                } else {
                    setConnectionState({state:"failed", message:"Failed to test connection."});
                }
            }).catch((err) => {
                setConnectionState({state:"failed", message:"Failed to test connection."});
            });

        }
    }


    const handleSaveConnection = (values) => {
        if (!connectorCredentialId) return;

        saveConnection?.(connectorCredentialId);
    }

    const handleOAuthError = (message) => {
        setConnectionState({state:"failed", message: mode === "test" ? message : "Internal connector issue. Please try again later."});
    }

    const updateForm = (values) => {
        reset({
            ...formValues,
            ...values
        });
    }


    const handleOAuthLogin = ({success, code, params, message}) => {

        if (success) {

            const paramsString = params instanceof URLSearchParams 
                ? Object.fromEntries(params.entries())
                : params;
            reset({
                ...formValues, // Keep existing form values
                code,
                paramsString // Add OAuth values
            });
            setConnectionState({state:"connected", message:"Connection successful"});
        } else {
            setConnectionState({state:"failed", message});
        }
    }

    return (
        <div>
            <CommonSelect
                optionKey="authentication_type_id"
                optionValue="authentication_type_name"
                label="Connection Type"
                value={selectedAuthenticationTypeId}
                onChange={changeAuthenticationType}
                options={authenticationTypes}
            />
            <Box sx={{ mt: 4 }}>
                <TextField
                    className="field"
                    autoComplete="off"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="connectionName"
                    defaultValue={`${connectorName}${nextUserId ? ` ${nextUserId}` : ""}`}
                    label="Connection Name"
                    placeholder="Connection Name"
                />

            </Box>

            <AuthenticationFields authenticationFields={authenticationFields} control={control} />

            <div>
                <DataConCheckbox name="defaultAccount" control={control} label="Default Account" />
            </div>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {selectedAuthenticationTypeId !== '1' && (
                        <Button type="submit" variant="outlined" color="primary" disabled={connectionState === "loading" || !isValid} onClick={handleSubmit(handleTestConnection)}>
                            Test Connection
                        </Button>
                    )}

                    {selectedAuthenticationTypeId === '1' && <OauthPopup config={config} disabled={!isValid} connectorName={connectorName} onCode={handleTestConnection} onError={handleOAuthError} />}
                    {connectionState.state === "loading" && <CircularProgress size={30} />}
                    {connectionState.state === "connected" && (
                        <Tooltip title={connectionState.message}>
                            <Check color="success" />
                        </Tooltip>
                    )}

                    {connectionState.state === "failed" && (
                        <Tooltip title={connectionState.message}>
                            <Close color="error" />
                        </Tooltip>
                    )}

                </Box >


                <Button type="submit" variant="outlined" color="primary" disabled={connectionState.state === "idle" || connectionState.state === "loading"} onClick={handleSubmit(handleSaveConnection)}>
                    Save Connection
                </Button>
            </Box>
        </div>
    )
}

function AuthenticationFields({ authenticationFields, control }) {
    return (
        <div>
            {authenticationFields.filter(field => field.field_scope_id === "3").map(field => (
                <Box key={field.field_nm} sx={{ mt: 1 }}>
                    <AuthenticationField field={field} control={control} />
                </Box>
            ))}
            
            {/* {authenticationFields.filter(field => field.field_scope_id === "4").map(field => (
                <Box key={field.field_nm} sx={{ mt: 1 }}>
                    <AdvancedField field={field} control={control} />
                </Box>
            ))} */}
        </div>
    )
}
