export const CONNECTOR_REDUCER_TYPES = {
	APP_SELECTED: "APP_SELECTED",
	SET_CONNECTIONS: "SET_CONNECTIONS",
	CONNECTION_TEST: "CONNECTION_TEST",
	CONNECTION_SELECTED: "CONNECTION_SELECTED",
	GET_FIELDS_RESPONSE: "GET_FIELDS_RESPONSE",
	SET_SOURCE_FIELDS: "SET_SOURCE_FIELDS",
	SET_DESTINATION_MAPPING: "SET_DESTINATION_MAPPING",
	SET_CREDENTIAL_ID: "SET_CREDENTIAL_ID",
	SET_CONNECTOR_ID: "SET_CONNECTOR_ID",
	SET_SELECTED_OBJECT: "SET_SELECTED_OBJECT",
	SET_CONNECTOR_IMAGE: "SET_CONNECTOR_IMAGE",
	EDIT_PIPELINE_INIT: "EDIT_PIPELINE_INIT",
	TESTING_CONNECTION: "TESTING_CONNECTION",
	SET_SOURCE_FIELDS_LOADING: "SET_SOURCE_FIELDS_LOADING",
	SET_OPTIONS: "SET_OPTIONS",
};

export default function connectorReducer(state, action) {
	// console.log('SHOW', action.type);
	switch (action.type) {
		case CONNECTOR_REDUCER_TYPES.APP_SELECTED:
			return {
				...state,
				connectorId: action.payload.connectorId,
				connector: action.payload.connectors.find(
					(con) => +con.ConnectorID === +action.payload.connectorId
				),
				image: getConnectorImage(
					action.payload.connectors,
					action.payload.connectorId
				),
				connections: [],
				loadingConnections: true,
				credentialId: null,
				mapping: [],
				fields: [],
				selectedObject: null,
				getFieldsErrorMessage: "",
				objects: [],
				options: {},
				selectedObjectOptions: [],
			};

		case CONNECTOR_REDUCER_TYPES.SET_CONNECTIONS:
			return {
				...state,
				connections: action.payload.connections,
				loadingConnections: false,
				...(action.payload.credentialId !== undefined && {
					credentialId: action.payload.credentialId,
				}),
			};

		case CONNECTOR_REDUCER_TYPES.TESTING_CONNECTION:
			return {
				...state,
				testingConnection: action.payload.testing,
			};

		case CONNECTOR_REDUCER_TYPES.CONNECTION_TEST:
			return {
				...state,
				objects: action.payload.objects,
				connected: action.payload.objects.length > 0,
				connectionMessage: action.payload.message,
				testingConnection: false,
				selectedObjectOptions: action.payload.objects?.find(
					(obj) => obj?.object_id === state.selectedObject
				)?.options,
			};

		case CONNECTOR_REDUCER_TYPES.CONNECTION_SELECTED:
			return {
				...state,
				objects: [],
				connected: false,
				credentialId: action.payload.credentialId,
			};

		case CONNECTOR_REDUCER_TYPES.EDIT_PIPELINE_INIT:
			return {
				...state,
				connectorId: action.payload.connectorId,
				connector: action.payload.connectors.find(
					(con) => +con.ConnectorID === +action.payload.connectorId
				),
				image: getConnectorImage(
					action.payload.connectors,
					action.payload.connectorId
				),
				connections: [],
				credentialId: action.payload.credentialId,
				loadingConnections: true,
				mapping: action.payload.mapping,
				fields: [],
				selectedObject: action.payload.selectedObject,
				getFieldsErrorMessage: "",
				options: action.payload?.options || {},
			};

		case CONNECTOR_REDUCER_TYPES.SET_SOURCE_FIELDS:
			return {
				...state,
				fields: action.payload.fields,
				getFieldsErrorMessage:
					action.payload.getFieldsErrorMessage || "",
				fieldsLoading: false,
			};

		case CONNECTOR_REDUCER_TYPES.SET_DESTINATION_MAPPING:
			return {
				...state,
				mapping: action.payload.mapping,
			};

		case CONNECTOR_REDUCER_TYPES.SET_CREDENTIAL_ID:
			return {
				...state,
				credentialId: action.payload.credentialId,
			};

		case CONNECTOR_REDUCER_TYPES.SET_CONNECTOR_ID:
			return {
				...state,
				connectorId: action.payload.connectorId,
			};

		case CONNECTOR_REDUCER_TYPES.SET_SELECTED_OBJECT:
			return {
				...state,
				selectedObject: action.payload.selectedObject,
				selectedObjectName: action.payload?.selectedObjectName,
				selectedObjectOptions: state.objects?.find(
					(obj) => obj?.object_id === action.payload.selectedObject
				)?.options,
				options: action.payload.options,
			};

		// sourceState.objects?.find(obj => obj?.object_id === option)?.options

		case CONNECTOR_REDUCER_TYPES.SET_CONNECTOR_IMAGE:
			return {
				...state,
				image: action.payload.image,
			};

		case CONNECTOR_REDUCER_TYPES.SET_SOURCE_FIELDS_LOADING:
			return {
				...state,
				fieldsLoading: true,
			};

		case CONNECTOR_REDUCER_TYPES.SET_OPTIONS:
			return {
				...state,
				options: { ...state.options, ...action.payload.options },
			};

		default:
			throw new Error("Action not allowed");
	}
}

function getConnectorImage(connectors, connectorId) {
	const connector = connectors.find(
		(con) => +con.ConnectorID === +connectorId
	);

	if (!connector || !connector.SourceImageTXT) {
		return null; // Return null if no image is found
	}

	// ✅ Check if it's a URL
	if (
		connector.SourceImageTXT.startsWith("http") ||
		connector.SourceImageTXT.startsWith("//")
	) {
		return connector.SourceImageTXT; // Return URL directly
	}

	// ✅ Use require() only for local images
	try {
		return require(`../assets/images/app-icons/${connector.SourceImageTXT}`)
			.default;
	} catch (error) {
		console.error("Error loading image:", error);
		return null; // Return null if image is not found
	}
}
