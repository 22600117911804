import { useEffect, useState } from 'react';
import { APP_KEY } from '../config/constants';
import { UserAPIService } from '../config/api-service';

function getSavedValue(key, initialValue) {
    const savedValue = JSON.parse(localStorage.getItem(key));
    if (savedValue) return savedValue;

    if (initialValue instanceof Function) return initialValue();
    return initialValue;
}

export default function useLocalStorage(key, initialValue) {
    const [value, setValue] = useState(() => {
        return getSavedValue(key, initialValue)
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return [value, setValue];
}

export function useUserInfo() {
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        // Load user info from localStorage or fetch from API
        const loadUserInfo = async () => {
            const localStorageData = localStorage.getItem(APP_KEY);
            if (localStorageData) {
                setUserInfo(JSON.parse(localStorageData));
            } else {
                try {
                    const { user, permissionList } = await UserAPIService.getUserDetail();
                    setUserInfo({ user, permissions: permissionList });
                } catch (error) {
                    console.error("Error fetching user details:", error);
                }
            }
        };

        loadUserInfo();

        // Event listener for anonymousUserEventChange
        const handleAnonymousUserEventChange = () => {
            const localStorageUserData = localStorage.getItem(APP_KEY);
            if (localStorageUserData) {
                setUserInfo(JSON.parse(localStorageUserData));
            }
        };

        window.addEventListener("anonymousUserEventChange", handleAnonymousUserEventChange);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener("anonymousUserEventChange", handleAnonymousUserEventChange);
        };
    }, []);

    return { userInfo, setUserInfo };
}