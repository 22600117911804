import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, MenuItem, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {DataConTextField} from '../common/data-con-form/FormFields'
import { useAppContext } from '../../context/app.context'
import { APP_KEY } from '../../config/constants'

export default function NewFeatureRequestForm ({ featureTypes, handleSubmitNewFeature }) {
  const { anonymousUserEmail, setAnonymousUserEmail } = useAppContext()
  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: {
      featureName: '',
      featureType: '',
      featureDescription: ''
    }
  })

  const [userInfo, setUserInfo] = useState({});
  const [emailError, setEmailError] = useState("");
  const emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9+.]$/;

  const [loading, setLoading] = useState(false)
  const handleSubmitForm = async ({ featureName, featureType, featureDescription }) => {
    setLoading(true)
    if (userInfo?.user?.UserAnonymousFlg && !anonymousUserEmail) {
      setEmailError("Email address is required");
      setLoading(false)
      return;
    }
    else if (userInfo?.user?.UserAnonymousFlg && !emailReg.test(anonymousUserEmail)) {
      setLoading(false)
      return;
    }
    try {
      await handleSubmitNewFeature({
        FeatureRequestNM: featureName,
        FeatureRequestDSC: featureDescription,
        FeatureTypeID: featureType,
        EmailTXT: anonymousUserEmail || JSON.parse(localStorage.getItem(APP_KEY)).user?.EmailTXT
      })
      reset()
    } catch { }
    setLoading(false)
  }

  useEffect(() => {
    const localStorageData = localStorage.getItem(APP_KEY);
    if (localStorageData) {
      setUserInfo(JSON.parse(localStorageData));
    }
    window.addEventListener('anonymousUserEventChange', () => {
      const localStorageUserData = localStorage.getItem(APP_KEY)
      if (localStorageUserData) {
        setUserInfo(JSON.parse(localStorageUserData))
      }
    })
  }, []);

  return (
    <Box component="form" autoComplete="off">
      {
        userInfo?.user?.UserAnonymousFlg &&
        <TextField
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          fullWidth
          type='email'
          value={anonymousUserEmail || ""}
          onChange={(e) => {
            // setEmail(e.target.value);
            setAnonymousUserEmail(e.target.value);
            setEmailError("");
          }}
          required
          name="email"
          error={emailError !== "" || (!emailReg.test(anonymousUserEmail) && anonymousUserEmail !== "")}
          label="Email"
          helperText={emailError ? emailError : !emailReg.test(anonymousUserEmail) && anonymousUserEmail !== "" ? "Invalid Email!" : null}
        />
      }
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2 }}>
        <DataConTextField
          className="field"
          autoComplete="off"
          size="small"
          variant="outlined"
          label="Feature Name"
          type="text"
          name="featureName"
          required
          control={control}
          rules={{ required: `Feature Name is required` }}
        />
        <DataConTextField
          className="field"
          autoComplete="off"
          size="small"
          variant="outlined"
          label="Feature Type"
          select
          name="featureType"
          required
          control={control}
          rules={{ required: `Feature Type is required` }}
        >
          {featureTypes.map((option) => (
            <MenuItem key={option.FeatureTypeID} value={option.FeatureTypeID}>
              {option.FeatureTypeNM}
            </MenuItem>
          ))}
        </DataConTextField>
      </Box>
      <DataConTextField
        className="field"
        autoComplete="off"
        size="small"
        fullWidth
        variant="outlined"
        label="Feature Description"
        multiline
        rows={8}
        name="featureDescription"
        required
        control={control}
        rules={{ required: `Feature Description is required` }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <LoadingButton variant="contained" color="primary" sx={{ px: 8 }} loading={loading} onClick={handleSubmit(handleSubmitForm)}>Save</LoadingButton>
      </Box>
    </Box>
  )
}