import { useForm } from 'react-hook-form'
import { Box, Button } from '@mui/material'
import {DataConTextField} from '../../common/data-con-form/FormFields'

export default function ChangePasswordForm ({ handleFormSubmit, handleCancel }) {
  const { handleSubmit, control, trigger, getValues } = useForm({
    mode: 'all',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  })

  const onSubmitForm = async (values) => {
    handleFormSubmit(values)
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { my: 1, width: 1 }
      }}
      noValidate
      autoComplete="off"
    >
      <DataConTextField
        required
        label="Old Password"
        autoComplete="new-password"
        type="password"
        name="oldPassword"
        control={control}
        rules={{ required: 'Old password required' }}
      />
      <DataConTextField
        required
        label="New Password"
        autoComplete="new-password"
        type="password"
        name="newPassword"
        control={control}
        rules={{
          required: 'New password required',
          validate: () => {
            trigger('confirmPassword')
            return true
          },
          minLength: {
            value: 6,
            message: 'Minimum 6 characters required'
          }
        }}
      />
      <DataConTextField
        required
        label="Repeat New Password"
        autoComplete="new-password"
        type="password"
        name="confirmPassword"
        control={control}
        rules={{
          required: 'Confirm password required',
          validate: (value) => {
            if (value === getValues()['newPassword']) {
              return true
            } else {
              return 'Passwords do not match'
            }
          }
        }}
      />

      <Button type="submit" color="primary" onClick={handleSubmit(onSubmitForm)} variant="contained" sx={{ mt: 1 }}>
        Submit
      </Button>
      <Button color="primary" onClick={handleCancel} variant="outlined" sx={{ mt: 1, ml: 1 }}>
        Cancel
      </Button>
    </Box>
  )
}