import {
	Box,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";

import { ConnectorAPIService } from "../../../../config/api-service";
import { useConnectorModalContext } from "../../connectors.context";
import AuthenticationFieldLoader from "../../DevTab/Authentication/AuthenticationFieldLoader";

const changeColor = (defaultInput, userInput) => {
	return userInput !== null && userInput !== defaultInput;
};

const CredentialsManagement = () => {
	const [credentialsManagementInfo, setCredentialsManagementInfo] =
		useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isFieldLoading, setIsFieldLoading] = useState(false);
	const [authenticationInfo, setAuthenticationInfo] = useState({});
	const [authFields, setAuthFields] = useState([]);

	const [authTypes, setAuthTypes] = useState([]);
	const [activeTask, setActiveTask] = useState(null);
	const [initialRender, setInitialRender] = useState(true);
	const [authType, setAuthType] = useState(null);
	const { selectedConnector, userInfo, newAuthTypeAdded } =
		useConnectorModalContext();

	useEffect(() => {
		setIsLoading(true);
		ConnectorAPIService.getConnectorCredentialsManagement(
			selectedConnector.ConnectorID
		)
			.then((response) => {
				setCredentialsManagementInfo(
					response.connectorCredentialsManagement
				);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, setCredentialsManagementInfo]);

	//========== AUTH TYPE ============
	const handleAuthTypeChange = async (e) => {
		setIsFieldLoading(true);
		setAuthenticationInfo({});
		setAuthType(e.target.value);
		if (!e.target.value) {
			setAuthFields([]);
			setIsFieldLoading(false);
			return;
		}

		ConnectorAPIService.getConnectorAuthenticationTypeFields(
			selectedConnector.ConnectorID,
			e.target.value,
			"2"
		)
			.then((res) => {
				setAuthFields(res.authenticationTypeFieldList);
				setIsFieldLoading(false);
			})
			.catch((err) => {
				setIsFieldLoading(false);
			});
	};
	useEffect(() => {
		setIsLoading(true);

		ConnectorAPIService.getConnectorAuthenticationTypes(
			selectedConnector.ConnectorID
		)
			.then((res) => {
				res.authenticationDetailList.sort(function (a, b) {
					return a.authentication_type_name.localeCompare(
						b.authentication_type_name
					);
				});
				setAuthTypes(res.authenticationDetailList);

				if (initialRender) {
					handleAuthTypeChange({
						target: {
							value: res?.authenticationDetailList?.[0]
								?.authentication_type_id,
						},
					});
					setInitialRender(false);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, newAuthTypeAdded]);

	const updateAuthenticationField = async () => {
		try {
			if (!authenticationInfo?.authentication_type_field_id) {
				return; // Don't send update if no field is selected
			}

			await ConnectorAPIService.updateConnectorAuthenticationTypeField(
				selectedConnector.ConnectorID,
				authenticationInfo.authentication_type_field_id,
				{
					field_value_user_input_dsc: authenticationInfo.field_value_user_input_dsc
				}
			);
		} catch (error) {
			console.error("Error updating authentication field:", error);
		}
	};
	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				Credentials Management
			</Divider>
			<Box
				sx={{
					mt: 4,
					display: "flex",
					justifyContent: "center",
					gap: "1rem",
				}}
			>
				<FormControl
					size="small"
					sx={{
						minWidth: 200,
						"& .MuiInputLabel-root": {
							top: "0px", // Adjust the label position above the border
							left: "0px",
							backgroundColor: "white", // Optional: Adds a background for better visibility
							padding: "0 4px",
						},
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								legend: {
									width: 0,
								},
							},
						},
					}}
				>
					<InputLabel id="auth_type" shrink>
						Auth Type
					</InputLabel>
					<Tooltip
						title={
							activeTask
								? `You cannot update this field when the task: ${activeTask?.name} is in an active state`
								: ""
						}
						placement="top"
					>
						<Select
							disabled={activeTask}
							labelId="auth_type"
							value={authType}
							onChange={(e) => handleAuthTypeChange(e)}
							label="Auth Type"
							sx={{
								"& .MuiSelect-select": {
									padding: "8px 14px", // Ensures proper alignment of dropdown text
								},
							}}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{authTypes.map((authType) => (
								<MenuItem
									key={authType.authentication_type_id}
									value={authType.authentication_type_id}
								>
									{authType.authentication_type_name}
								</MenuItem>
							))}
						</Select>
					</Tooltip>
				</FormControl>
			</Box>
			<Grid container spacing={2} sx={{ py: 4 }}>
				{isFieldLoading ? (
					<AuthenticationFieldLoader />
				) : authFields.length > 0 ? (
					authFields
						.filter(
							(authField) => authField?.field_scope_id === "2"
						)
						.map((authField) => (
							<Grid
								item
								xs={12}
								md={6}
								sx={{ gap: 1 }}
								key={authField?.authentication_type_field_id}
							>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<TextField
										disabled={activeTask}
										label={
											authField?.field_nm
												?.charAt(0)
												?.toUpperCase() +
											authField?.field_nm?.slice(1)
										}
										variant="outlined"
										size="small"
										value={
											authField?.field_value_user_input_dsc ||
											authField?.field_value_dsc
										}
										onChange={(e) => {
											const newValue = {
												authentication_type_field_id: authField.authentication_type_field_id,
												field_value_user_input_dsc: e.target.value || null,
											};
											setAuthenticationInfo(newValue);

											const newAuthFields = authFields.map((field) => {
												if (field.authentication_type_field_id === authField.authentication_type_field_id) {
													return {
														...field,
														field_value_user_input_dsc: e.target.value || null,
													};
												}
												return field;
											});

											setAuthFields(newAuthFields);
										}}
										onBlur={updateAuthenticationField}
										InputLabelProps={{
											shrink: true,
											sx: {
												color:
													changeColor(
														authField?.field_value_dsc,
														authField?.field_value_user_input_dsc
													) && "var(--data-con-blue)",
											},
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											padding: 0,
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													authField?.field_value_dsc,
													authField?.field_value_user_input_dsc
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									/>
								</Tooltip>
							</Grid>
						))
				) : (
					<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
						<Typography variant="body1">
							No app credentials required for the selected authentication type.
						</Typography>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default CredentialsManagement;
