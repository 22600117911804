import PlayIcon from '@mui/icons-material/PlayArrow';
import { 
	Button,
	Card,
	CardActionArea,
	CardMedia,
	Checkbox,
	IconButton,
	Modal,
	TextField,
	Typography
} from '@mui/material';
import ALink from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import deleteIcon from '../../assets/images/delete.png';
import userEditIcon from '../../assets/images/pipeline-edit-icon.png';
import stopIcon from '../../assets/images/stop.png';
import {
	ConfigureAPIService,
	EventLogAPIService,
	PipelineAPIService
} from '../../config/api-service';
import { APP_KEY, ORGANIZATIONS } from "../../config/constants";
import MaterialUITheme from '../../config/material-ui-theme';
import useScript from '../../config/useScript';
import { useConnectorContext } from '../../context/connector.context';
import AlertDialog from "../common/dialog";
import Register from '../navigation/Register';
import RunHistoryComponent from './run-history';
import "./style.css";
import EnablePipelineDialogContent from './EnablePipelineDialogContent';
import { useUserInfo } from '../../hooks/useLocalStorage';


const ControlledCheckbox = ({ value, handleChange }) => {
	const [checked, setChecked] = useState(false);
	const [openRegisterModal, setOpenRegisterModal] = useState(false);
	const { userInfo } = useUserInfo();

	useEffect(() => {
		if (!userInfo?.user?.UserAnonymousFlg) {
			setChecked(value);
		}
	}, [value]);

	const handleCheck = (event) => {
		if (!userInfo?.user?.UserAnonymousFlg) {
			handleChange(event.target.checked);
		}
		else {
			setOpenRegisterModal(true);
		}
	}

	return (
		<>
			<Checkbox
				color="primary"
				checked={checked}
				onChange={handleCheck}
			/>
			{
				openRegisterModal && <Register openRegisterModal={openRegisterModal} setOpenRegisterModal={setOpenRegisterModal}></Register>
			}
		</>
	)
}

export default function PipelinesComponent() {
	const [pipelines, setPipelines] = useState([]);
	const [filteredPipelines, setFilteredPipelines] = useState([]);
	const [loadingPipelines, setLoadingPipelines] = useState(true);
	const [searchText, setSearchText] = useState('');
	const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
	const [openRunDialog, setOpenRunDialog] = useState(false);
	const [openStopDialog, setOpenStopDialog] = useState(false);
	const [openLogsDialog, setOpenLogsDialog] = useState(false);
	const [selectedJobID, setSelectedJobID] = useState({});
	const { connectors } = useConnectorContext();
	const [pipelineSchedule, setPipelineSchedule] = useState();
	let history = useHistory();

	const getPipelines = () => {
		setLoadingPipelines(true);
		PipelineAPIService.getPipelines()
			.then(json => {
				if (json) {
					setPipelines(json);
					setFilteredPipelines(json);
					setLoadingPipelines(false);
				}
			})
	}


	// useEffect(() => {
	//     if (typeof window !== "undefined") {
	//         window.addEventListener('organizationChanged', () => {
	//             const localStorageData = localStorage.getItem(ORGANIZATIONS)
	//             if (localStorageData) {
	//                 setTrialEndTime(JSON.parse(localStorageData)?.organizationTRL?.free_trial_end_dts)
	//             }
	//         })
	//     }
	// }, [])

	// useEffect(() => {
	//     const localStorageData = localStorage.getItem(ORGANIZATIONS)
	//     if (JSON.parse(localStorageData)?.organizationTRL?.free_trial_end_dts) {
	//         setTrialEndTime(JSON.parse(localStorageData)?.organizationTRL?.free_trial_end_dts)
	//     }
	// }, [])

	useEffect(() => {
		getPipelines();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (pipelines.some(p => p.status_dsc === 'Running')) {
			setTimeout(() => {
				PipelineAPIService.getPipelines()
					.then(json => {
						if (json) {
							setPipelines(json)
							setFilteredPipelines(json)
						}
					})
			}, 5000)
		}

	}, [pipelines])

	useScript()

	const handleRunJob = async () => {
		await ConfigureAPIService.postInvoke(openRunDialog)
		setOpenRunDialog(false)
		getPipelines()
	}

	const handleStopJob = async () => {
		setOpenStopDialog(false)
	}

	const handleEditJob = job => {
		history.push("pipelines/" + job);
	}

	const handleDeleteJob = () => {
		ConfigureAPIService.deleteJob(openDiscardDialog)
			.then(res => {
				getPipelines();
				setOpenDiscardDialog(false)
			});
	}

	const formatDate = (value) => {
		let MM, dd, yy, hh, mm, ap, formatted = '';

		const formatHour = (hour) => {
			if (hour === 0) return 12
			if (hour > 12) return hour - 12
			return hour
		}

		if (value) {
			function isValidDate(d) {
				return d instanceof Date && !isNaN(d);
			}
			let valueDate = new Date(value);
			if (isValidDate(valueDate)) {
				dd = String(valueDate.getDate()).padStart(2, '0');
				MM = String(valueDate.getMonth() + 1).padStart(2, '0');
				yy = String(valueDate.getFullYear()).substring(2);
				hh = String(formatHour(valueDate.getHours()))
				mm = String(valueDate.getMinutes()).padStart(2, '0');
				ap = (valueDate.getHours() > 12) ? 'PM' : 'AM';

				formatted = `${MM}/${dd}/${yy} ${hh}:${mm} ${ap}`
			}
			else {
				formatted = value
			}
		}

		return formatted;
	}

	const formatRowCount = (value) => {
		let digi = ['K', 'M', 'B']
		let final = value
		value = parseInt(value);

		if (value > 999) {
			value = ((value - (value % 1000)) / 1000).toString();
			final = value + digi[0]
		} else if (value > 999999) {
			value = ((value - (value % 1000000)) / 1000000).toString();
			final = value + digi[1]
		} else if (value > 999999999) {
			value = ((value - (value % 1000000000)) / 1000000000).toString();
			final = value + digi[3]
		}

		return final
	}

	const columns = [
		{
			field: 'source_connector_id',
			headerName: 'SOURCE',
			headerClassName: 'pipelines-table-header',
			description: 'The source connector of the pipeline',
			width: 100,
			align: 'center',
			headerAlign: 'left',
			renderCell: (props) => {
				let connectorID = props.getValue(props.id, "source_connector_id");

				let app = connectors.find(conn => conn.ConnectorID === connectorID);

				if (!app) return "";
				const appImage = require(`./../../assets/images/app-icons/${app.SourceImageTXT}`).default
				return (
					<Tooltip title={
						<div>
							<b>Connector:</b> {props.row.source_connector_name}
							<br />
							<b>Connection:</b> {props.row.source_connection_name}
						</div>
					}>
						<img src={appImage} alt={app.ConnectorNM} style={{ height: '90%', borderRadius: 3 }} />
					</Tooltip>
				)
			}
		},
		{
			field: 'destination_connector_id',
			headerName: 'DEST',
			headerClassName: 'pipelines-table-header',
			description: 'The destination connector of the pipeline',
			width: 90,
			align: 'center',
			headerAlign: 'left',
			renderCell: (props) => {
				let connectorID = props.getValue(props.id, "destination_connector_id");

				let app = connectors.find(conn => conn.ConnectorID === connectorID);
				if (!app) return "";
				const appImage = require(`./../../assets/images/app-icons/${app.SourceImageTXT}`).default
				return (
					<Tooltip title={
						<div>
							<b>Connector:</b> {props.row.destination_connector_name}
							<br />
							<b>Connection:</b> {props.row.destination_connection_name}
						</div>
					}>
						<img src={appImage} alt={app.ConnectorNM} style={{ height: '90%', borderRadius: 3 }} />
					</Tooltip>
				)
			}
		},
		{
			field: 'pipeline_nm',
			headerName: 'PIPELINE NAME',
			headerClassName: 'pipelines-table-header',
			description: 'Pipeline name',
			minWidth: 200,
			flex: 1,
			headerAlign: 'left',
			editable: true,
			renderCell: (params) => (
				<Tooltip title={params.row.pipeline_nm} >
					<span className="table-cell-trucate">{params.row.pipeline_nm}</span>
				</Tooltip>
			),
		},
		{
			field: 'start_dts',
			headerName: 'LAST RUN',
			description: 'Last time the pipeline was ran',
			valueFormatter: ({ value }) => formatDate(value),
			headerClassName: 'pipelines-table-header',
			minWidth: 150,
			headerAlign: 'left',
		},
		{
			field: 'rows_inserted_nbr',
			headerName: 'ROWS',
			description: 'Count of rows inserted in the last run',
			valueFormatter: ({ value }) => formatRowCount(value),
			minWidth: 70,
			headerClassName: 'pipelines-table-header',
			headerAlign: 'left',
		},
		{
			field: 'next_run_dts',
			headerName: 'NEXT RUN',
			description: 'Next date/time the pipeline will run',
			valueFormatter: ({ value }) => formatDate(value),
			headerClassName: 'pipelines-table-header',
			headerAlign: 'left',
			minWidth: 150,
		},
		{
			field: 'status_dsc',
			headerName: 'STATUS',
			description: 'Current status of a pipeline. Finished - pipeline is done running. Running - pipeline is currently running. Failed - pipeline failed and is not running.',
			minWidth: 90,
			headerClassName: 'pipelines-table-header',
			headerAlign: 'left',
			cellClassName: (params) => {
				if (params.value === "Failed") return 'run_result_fail'
			},
			renderCell: (props) => {
				if (props.value === "Running") return (
					<ALink component="button" onClick={() => {
						setSelectedJobID({ id: props.id, isStatus: true })
						setOpenLogsDialog(true);
					}}
					>
						{props.value}
					</ALink>
				)
			}
		},
		// {
		//     field: 'progress',
		//     headerName: 'PROGRESS',
		//     description: 'Current progress % of pipeline that is currently running',
		//     headerClassName: 'pipelines-table-header',
		//     headerAlign: 'left',
		//     minWidth: 90,
		// },
		{
			field: 'successPercentage',
			headerName: 'SUCCESS',
			description: 'The success % of pipeline runs',
			headerClassName: 'pipelines-table-header',
			headerAlign: 'left',
			minWidth: 80,
			valueFormatter: ({ value }) => value === null ? '-' : `${value}%`
		},
		{
			field: 'logs',
			headerName: 'HISTORY',
			description: 'View the logs of the pipeline runs',
			headerClassName: 'pipelines-table-header',
			minWidth: 90,
			align: 'center',
			headerAlign: 'left',
			sortable: false,
			filterable: false,
			renderCell: (props) => (<>
				<ALink component="button" onClick={() => {
					setSelectedJobID({ id: props.id, isStatus: false })
					setOpenLogsDialog(true);
				}}
				>
					View
				</ALink>
			</>)
		},
		{
			field: 'actions',
			headerName: 'ACTIONS',
			description: 'Play button - run the pipeline. Edit button - edit the pipeline. Delete button - delete the pipeline',
			headerClassName: 'pipelines-table-header',
			minWidth: 140,
			sortable: false,
			filterable: false,
			align: 'center',
			headerAlign: 'center',
			renderCell: (props) => {
				return (
					<div style={{ width: '90%', display: 'flex', justifyContent: 'space-between' }}>
						{
							(props.getValue(props.id, 'status_dsc') === "Running")
								? (<IconButton
									key="1"
									size="small"
									color="primary"
									onClick={() => setOpenStopDialog(props.id)}
								>
									<div style={{ height: 20, width: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<img src={stopIcon} alt="Delete Icon" style={{ width: 14, marginTop: 1 }} />
									</div>
								</IconButton>)
								: (<IconButton
									key="1"
									size="small"
									color="primary"
									onClick={() => setOpenRunDialog(props.id)}
								>
									<PlayIcon fontSize="small" />
								</IconButton>)
						}
						<IconButton
							key="2"
							size="small"
							onClick={() => handleEditJob(props.id)}
						>
							<img src={userEditIcon} alt="Edit Icon" style={{ width: 14, marginTop: 1 }} />
						</IconButton>
						<IconButton
							key="3"
							size="small"
							color="error"
							onClick={() => { setOpenDiscardDialog(props.id) }}
						>
							<img src={deleteIcon} alt="Delete Icon" style={{ width: 13 }} />
						</IconButton>
					</div>
				)
			}
		},
		{
			field: 'enabled_flg',
			headerName: 'ENABLED',
			description: 'Enable or disable pipelines',
			headerClassName: 'pipelines-table-header',
			minWidth: 100,
			align: 'center',
			headerAlign: 'left',
			sortable: false,
			filterable: false,
			renderCell: ({ id, value }) => {
				return <ControlledCheckbox
					value={!!value}
					handleChange={(status) => handleConfirmPipelineDialog(id, status)}
				/>
			}
		}
	];

	const [pipelineDialog, setPipelineDialog] = useState(null)
	const handleConfirmPipelineDialog = (id, status) => {
		setPipelineDialog({ id, status })
	}

	const handleEnableDisablePipeline = async ({ id, status }) => {
		const { message, success } = await PipelineAPIService.enableDisablePipelines({ id, status: status ? 'enable' : 'disable', schedule: pipelineSchedule })
		setPipelineDialog(null)
		getPipelines()
		if (success) {
			toast.success(message)
		} else {
			toast.error(message)
		}
	}

	const handleLogEvent = () => {
		EventLogAPIService.addPipelineClicked();
	}

	const CustomNoRowsOverlay = () => {
		if (pipelines?.length) {
			return <GridOverlay>
				Your search has returned no pipelines.
			</GridOverlay>
		}

		return <GridOverlay style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "flex-start", zIndex: 5 }}>
			<Typography sx={{ margin: 0, fontWeight: 400, fontSize: { sm: "1.8rem" } }}>Welcome to Data Connector!</Typography>
			<Typography sx={{ margin: "0 auto", fontWeight: 400, fontSize: { sm: "1.8rem" } }}>To get started, please click on the <br />"ADD PIPELINE" button.</Typography>
			<Typography sx={{ fontWeight: 400, my: "15px", fontSize: { sm: "1.8rem" } }}>If you would like help getting things setup, <br /> feel free to reach out to <br /> support@dataconnector.com</Typography>
			<div>
				<Card raised>
					<CardActionArea>
						<CardMedia
							sx={{ minWidth: { xs: 300, sm: 400, md: 550 }, minHeight: { xs: 200, sm: 250, md: 300 } }}
							component="iframe"
							alt="header"
							// height="300"
							src="https://www.youtube.com/embed/JLBLJqUxoWU"
						/>
					</CardActionArea>
				</Card>
			</div>

		</GridOverlay>
	}

	const searchChange = (e) => {
		let searchValue = e.target.value.toLowerCase();
		setSearchText(e.target.value);
		const filteredResults = pipelines.filter(row => (row.pipeline_nm).toLowerCase().includes(searchValue));
		setFilteredPipelines(filteredResults);
	}

	return (
		<MaterialUITheme>
			{/* {trialEndTime && <TimerComponent trialEndTime={trialEndTime} />} */}
			<Box component={'div'} sx={{ maxWidth: 1425, px: 2, mx: 'auto' }}>
				<div className="text title" style={{ fontWeight: 400, fontSize: '2.5rem', marginTop: 15 }}>Pipelines</div>

				<Box sx={{ display: { sm: 'flex' }, justifyContent: { sm: 'space-between' }, textAlign: "center", my: 1.2 }}>
					<Button
						component={Link}
						variant="contained"
						to={'pipelines/new'}
						onClick={handleLogEvent}
						disableElevation
						size="large"
						sx={{ backgroundColor: 'var(--data-con-blue)', fontSize: 18, py: 0.5 }}
					>
						ADD PIPELINE
					</Button>
					<TextField
						sx={{ display: { xs: "none", sm: "block" } }}
						variant="outlined"
						size="small"
						placeholder="Search Pipelines"
						value={searchText}
						onChange={searchChange}
					/>
				</Box>


				<div className="pipelines-table-container">
					<DataGrid
						rows={filteredPipelines}
						columns={columns}
						disableSelectionOnClick
						density="compact"
						disableColumnMenu
						components={{
							NoRowsOverlay: CustomNoRowsOverlay
						}}
						hideFooter
						loading={loadingPipelines}
					/>
				</div>

				<AlertDialog
					open={openDiscardDialog}
					handleConfirm={handleDeleteJob}
					handleClose={() => setOpenDiscardDialog(false)}
				>
					Are you sure you want to delete this pipeline?
				</AlertDialog>
				<AlertDialog
					open={openRunDialog}
					handleConfirm={handleRunJob}
					handleClose={() => setOpenRunDialog(false)}
					highlightYes
				>
					Are you sure you want to run this pipeline manually?
				</AlertDialog>
				<AlertDialog
					open={openStopDialog}
					handleConfirm={handleStopJob}
					handleClose={() => setOpenStopDialog(false)}
					highlightYes
				>
					Are you sure you want to stop this pipeline manually?
				</AlertDialog>
				<AlertDialog
					open={pipelineDialog}
					handleConfirm={() => handleEnableDisablePipeline(pipelineDialog)}
					handleClose={() => setPipelineDialog(null)}
					highlightYes
				>
					<EnablePipelineDialogContent pipelineDialog={pipelineDialog} pipelineSchedule={pipelineSchedule} setPipelineSchedule={setPipelineSchedule} />
				</AlertDialog>
				<Modal
					open={openLogsDialog}
					onClose={() => setOpenLogsDialog(false)}
				>
					<RunHistoryComponent job={selectedJobID.id} openRunning={selectedJobID.isStatus} handleClose={() => setOpenLogsDialog(false)} />
				</Modal>
			</Box>
		</MaterialUITheme>)
}