import React, { useState, useEffect } from "react";
import OauthPopup from "react-oauth-popup";
import { useForm } from "react-hook-form";
import GoogleButton from 'react-google-button'
import {
    Button,
    Checkbox,
    FormControlLabel,
    InputAdornment,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton'
import {DataConTextField} from "../../common/data-con-form/FormFields";

export default function OauthModal ({
    app,
    authenticationMethod: {
        AuthorizationUrlTXT,
        RedirectUrlPrefixTXT,
        AuthenticationMethodID,
        EnvironmentKeyNM,
        OauthFrameWidthNBR,
        OauthFrameHeightNBR,
        fields
    },
    saveConnection,
    loading,
    connection_nm,
    connector_default_flg
}) {
    const [authURL, setAuthURL] = useState();
    const [showSave, setShowSave] = useState(false);
    const [oauthCredentials, setOauthCredentials] = useState();
    const [defaultAccount, setDefaultAccount] = useState(connector_default_flg === 1);
    const [errorMessage, setErrorMessage] = useState("");

    const { control: preformControl, formState } = useForm({
        mode: "all",
    });

    const { handleSubmit, control } = useForm({
        mode: "all",
        defaultValues: {
            connectionName: connection_nm || "",
        },
    });

    useEffect(() => {
        let redirectURL = window.location.origin + "/oauth";

        console.log(AuthorizationUrlTXT + RedirectUrlPrefixTXT + redirectURL);

        setAuthURL(AuthorizationUrlTXT + RedirectUrlPrefixTXT + redirectURL);

    }, [AuthorizationUrlTXT, RedirectUrlPrefixTXT]);

    const oauthCodeResponse = (code, params) => {
        let parm = {};
        params.forEach((value, key) => (parm[key] = value));
        if (!parm["error"]) {
            setOauthCredentials(parm);
            setShowSave(true);
        } else {
            setErrorMessage(
                "Canceled with status message: " +
                parm["error"] +
                `. Please reauthenticate by clicking the ${app.ConnectorNM} LOGIN button.`
            );
        }

        // TODO: Show message on error
    };

    const oauthClose = () => { };

    const handleSave = (values) => {
        const payload = {
            authenticationMethod: AuthenticationMethodID,
            ...values,
            defaultAccount,
            ...oauthCredentials,
            environment: EnvironmentKeyNM,
            redirectURL: window.location.origin + "/oauth",
        };

        saveConnection({connectorId: app.ConnectorID, payload});
    };

    const handleUrlReplace = (e) => {
        let redirectURL = window.location.origin + "/oauth";
        setAuthURL(AuthorizationUrlTXT.replace("{" + e.target.name + "}", e.target.value) + RedirectUrlPrefixTXT + redirectURL);
    }

    return (
        <>
            {showSave ? (
                <>
                    <form>
                        <span>Name your connection</span>
                        <DataConTextField
                            className="field"
                            size="small"
                            fullWidth
                            variant="outlined"
                            label="Connection Name"
                            type="text"
                            name="connectionName"
                            required
                            control={control}
                            rules={{ required: `Connection Name is required` }}
                        />

                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="primary"
                                        checked={defaultAccount}
                                        onChange={(e) =>
                                            setDefaultAccount(e.target.checked)
                                        }
                                    />
                                }
                                label="Default Connection"
                            />
                        </div>

                        <div
                            style={{
                                borderTop: "1px solid #ccc",
                                margin: "20px 0px",
                            }}
                        ></div>

                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={loading}
                            onClick={handleSubmit(handleSave)}
                        >
                            Save
                        </LoadingButton>
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setShowSave(false);
                            }}
                            style={{ float: "right" }}
                        >
                            Cancel
                        </Button>
                    </form>
                </>
            ) : (
                <div style={{ textAlign: "center" }}>
                    {fields && fields.map(({ FieldKeyNM, FieldLabelTXT, FieldTypeTXT, FieldDefaultTXT: val, FieldHintTXT, FieldPlaceholderTXT, FieldRequiredFLG, FieldEndAdornmentTXT }) => (
                        <DataConTextField
                            key={FieldKeyNM}
                            autoComplete="new-password"
                            className="field"
                            size="small"
                            fullWidth
                            variant="outlined"
                            label={FieldLabelTXT}
                            type={FieldTypeTXT}
                            name={FieldKeyNM}
                            // helperText={FieldHintTXT}
                            placeholder={FieldPlaceholderTXT}
                            defaultValue={val}
                            required={+FieldRequiredFLG === 1}
                            control={preformControl}
                            rules={FieldRequiredFLG ? { required: `${FieldLabelTXT} is required` } : null}
                            endAdornment={FieldEndAdornmentTXT || ""}
                            onBlur={handleUrlReplace}
                        />
                    ))}
                    <p style={{ marginBotton: 20, marginTop: 0 }}>
                        Use the button below to authenticate with{" "}
                        {app.ConnectorNM}
                    </p>
                    {fields && fields.length && !formState.isValid ? (<Button
                            variant="contained"
                            color="primary"
                            disabled={true}
                        >
                            {app.ConnectorNM} Login
                        </Button>) : (
                            <OauthPopup
                                url={authURL}
                                onCode={oauthCodeResponse}
                                onClose={oauthClose}
                                width={OauthFrameWidthNBR || 500}
                                height={OauthFrameHeightNBR || 500}
                            >
                            {app.ConnectorNM.includes("Google") ? (
                                <GoogleButton
                                    type="light"
                                    style={{margin: "0 auto"}}
                                />
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                >
                                    {app.ConnectorNM} Login
                                </Button>
                            )}
                            </OauthPopup>
                        )}
                </div>
            )}
            <div
                style={{
                    color: "red",
                    paddingTop: 5,
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                {errorMessage}
            </div>
        </>
    );
}
