import { useState, useEffect } from "react";
import { DataConTextField, DataConCheckbox, DataConSelect, DataConRadio } from "../common/data-con-form/FormFields";
import { Grid, Skeleton, Typography } from "@mui/material";
import { useForm } from "react-hook-form";

export function AuthenticationFields({ fields, onSubmit, defaultValues }) {
    const { handleSubmit, control, reset, formState: { isValid } } = useForm({
        mode: 'all',
        defaultValues
    })

    return (
        <Grid container spacing={2} sx={{ py: 4 }}>
            {fields.map(field => (
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ gap: 1 }}
                    key={
                        field.authentication_type_field_id
                    }
                >
                    <AuthenticationField field={field} control={control} />
                </Grid>
            ))}
        </Grid>
    )

}


export function AuthenticationField({ field, control }) {
    switch (field.field_data_type_txt) {
        case "text":
        case "password":
        case "number":
            return <AuthenticationTextField field={field} control={control} />
        case "checkbox":
            return <AuthenticationCheckboxField field={field} control={control} />
        case "select":
            return <AuthenticationSelectField field={field} control={control} />
        case "radio":
            return <AuthenticationRadioField field={field} control={control} />
        default:
            return null
    }
}
function AuthenticationTextField({ field, control }) {
    return (<DataConTextField
        key={field.authentication_type_field_id}
        autoComplete="off"
        className="field"
        size="small"
        fullWidth
        variant="outlined"
        label={field.field_user_friendly_name}
        type={field.field_data_type_txt}
        name={field.field_nm}
        placeholder={field.placeholder_value_txt}
        defaultValue={field.default_value_txt}
        required={field.required_flg === 1}
        control={control}
        endAdornment={field.end_adornment_txt || ""}
        rules={field.required_flg ? { required: `${field.field_user_friendly_name} is required` } : null}
    />)
}

function AuthenticationCheckboxField({ field, control }) {
    return (<DataConCheckbox

    />)
}

function AuthenticationSelectField({ field, control }) {
    return (<DataConSelect
        key={field.authentication_type_field_id}
        control={control}
        name={field.field_nm}
        label={field.field_user_friendly_name}
        options={field.allowed_values_txt.split(",")}
    />)
}

function AuthenticationRadioField({ field, control }) {
    return (<DataConRadio

    />)
}


function AuthenticationFieldLoader() {
    return (
        <>
            <Typography
                sx={{
                    textAlign: "left",
                    wordBreak: "break-word",
                    fontSize: { xs: "12px", md: "14px" },
                    color: "var(--data-con-blue)",
                }}
            >
                Loading form...
            </Typography>
            <Grid container spacing={1} sx={{ pb: 4, pt: 2 }}>
                <Grid item xs={12} md={6}>
                    <Skeleton
                        variant="rounded"
                        height={40}
                        width="100%"
                        sx={{ borderRadius: "5px" }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton
                        variant="rounded"
                        height={40}
                        width="100%"
                        sx={{ borderRadius: "5px" }}
                    />
                </Grid>
            </Grid>
        </>
    );
};