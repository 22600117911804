import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, MenuItem, Checkbox, FormControlLabel, TextField, Switch, Tooltip } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DataConTextField} from '../common/data-con-form/FormFields'

export default function FeatureReviewForm ({ data = {}, featureTypes, handleSubmitFeatureReview }) {
  const { handleSubmit, control, reset, trigger } = useForm({
    mode: 'all',
    defaultValues: {
      featureName: '',
      featureType: '',
      featureDescription: '',
      organization: '',
      feedBack: '',
      userName: ''
    }
  })

  const defaultValues = {
    approve: false,
    display: false
  }
  const [inputs, setInputs] = useState(defaultValues)
  const handleCheck = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.checked })
  }

  const [projectedDate, setProjectedDate] = useState(null)

  const [loading, setLoading] = useState(false)
  const handleSubmitForm = async (values) => {
    setLoading(true)
    await handleSubmitFeatureReview({ ...values, ...inputs, projectedDate })
    reset()
    setLoading(false)
  }

  useEffect(() => {
    if (data && Object.keys(data)?.length) {
      reset({
        featureName: data.feature_request_nm || '',
        featureType: data.feature_type_id || '',
        featureDescription: data.feature_request_dsc || '',
        organization: data.organization_nm || '',
        feedBack: data.feature_request_feedback_txt || '',
        userName: `${data.first_nm} ${data.last_nm}`
      })
      setInputs({
        approve: !!data.feature_accepted_flg,
        display: !!data.display_flg
      })
      setProjectedDate(data.feature_implementation_dts)
    }
  }, [data])

  return (
    <Box component="form" autoComplete="off">
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1 }}>
        <DataConTextField
          className="field"
          autoComplete="off"
          size="small"
          fullWidth
          variant="outlined"
          label="Feature Name"
          type="text"
          name="featureName"
          required
          control={control}
          rules={{ required: `Feature Name is required` }}
        />
        <DataConTextField
          className="field"
          autoComplete="off"
          size="small"
          fullWidth
          variant="outlined"
          label="Feature Type"
          select
          name="featureType"
          required
          control={control}
          rules={{ required: `Feature Type is required` }}
        >
          {featureTypes.map((option) => (
            <MenuItem key={option.FeatureTypeID} value={option.FeatureTypeID}>
              {option.FeatureTypeNM}
            </MenuItem>
          ))}
        </DataConTextField>
        <DataConTextField
          className="field"
          autoComplete="off"
          size="small"
          variant="outlined"
          label="Organization"
          type="text"
          name="organization"
          control={control}
          InputProps={{ readOnly: true }}
        />
        <DataConTextField
          className="field"
          autoComplete="off"
          size="small"
          variant="outlined"
          label="User Name"
          type="text"
          name="userName"
          control={control}
          InputProps={{ readOnly: true }}
        />
      </Box>

      <DataConTextField
        className="field"
        autoComplete="off"
        size="small"
        fullWidth
        variant="outlined"
        label="Feature Description"
        multiline
        rows={6}
        name="featureDescription"
        required
        control={control}
        rules={{ required: `Feature Description is required` }}
      />
      <DataConTextField
        className="field"
        autoComplete="off"
        size="small"
        fullWidth
        variant="outlined"
        label="FeedBack (If declining or seeing an issue with feature)"
        multiline
        rows={6}
        name="feedBack"
        control={control}
        rules={{
          validate: (value) => {
            if (!!inputs['approve']) {
              return true
            } else if (!value) {
              return 'FeedBack is required if you want to decline this request'
            }
            else return true
          }
        }}
      />

      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2 }}>
        <FormControlLabel
          control={<Switch
            name="approve"
            checked={inputs['approve']}
            onChange={(e) => {handleCheck(e); trigger()}}
            inputProps={{ 'aria-label': 'controlled' }}
          />}
          label="Approve" />
        <FormControlLabel
          control={
            <Checkbox
              name="display"
              color="primary"
              checked={inputs['display']}
              onChange={handleCheck}
            />
          }
          label="Display"
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Projected Date"
            disablePast
            value={projectedDate}
            onChange={setProjectedDate}
            renderInput={(params) => <TextField size="small" sx={{ mt: 1 }} {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        {
          inputs['approve']
          ? <LoadingButton variant="contained" color="primary" sx={{ px: 8 }} loading={loading} onClick={handleSubmit(handleSubmitForm)}>Save</LoadingButton>
          : <Tooltip title={'You are declining this request'} placement="left-start">
            <LoadingButton variant="contained" color="primary" sx={{ px: 8 }} loading={loading} onClick={handleSubmit(handleSubmitForm)}>Save</LoadingButton>
          </Tooltip>
        }
      </Box>
    </Box>
  )
}