import React, { useState, useEffect } from 'react'
import { usePipelineContext } from "../pipeline.context";
import { ConnectorAPIService } from '../../../config/api-service';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify'
import { CONNECTOR_REDUCER_TYPES } from '../../../reducers/connectorReducer';
import AuthenticationMethod from './AuthenticationMethod';
import Dialog from '../../common/dialog'

export default function ConnectorAuthenticationModal ({ open, setOpen, source, connector_credential_id, connection_nm, connector_default_flg}) {
    const {
        sourceState,
        destinationState,
        connectors,
        dispatchSource,
        dispatchDestination,
        getConnections,
        testConnection,
    } = usePipelineContext();


    const [loading, setLoading] = useState(false);

    const [authenticationMethods, setAuthenticationMethods] = useState([]);

    const app = connectors.find(con => {
        if (source) {
            return con.ConnectorID === sourceState.connectorId
        } else {
            return con.ConnectorID === destinationState.connectorId
        }
    });

    const saveConnection = (payload) => {
        payload = Object.hasOwn(payload, 'payload') ? payload.payload : payload;
        setLoading(true);
        if (!connector_credential_id) {
            ConnectorAPIService.postConnection(source ? sourceState.connectorId : destinationState.connectorId, payload).then(res => {
                setLoading(false);
                setOpen(false);

                if(res?.errorMessage){
                    toast.error(res.errorMessage);
                } else if (res?.credentialID) {
                    if (source) {
                        dispatchSource({type: CONNECTOR_REDUCER_TYPES.SET_CREDENTIAL_ID, payload: {credentialId: res.credentialID}})
                        getConnections(sourceState.connectorId, dispatchSource, false);
                        testConnection(sourceState.connectorId, res?.credentialID, dispatchSource);
                    } else {
                        dispatchDestination({type: CONNECTOR_REDUCER_TYPES.SET_CREDENTIAL_ID, payload: {credentialId: res.credentialID}})
                        getConnections(destinationState.connectorId, dispatchDestination, false);
                        testConnection(destinationState.connectorId, res?.credentialID, dispatchDestination);
                    }

                } else {
                    toast.error("A internal server error has occurred.")
                }
            });
        } else {
            ConnectorAPIService.updateConnection({
                connector_id: source ? sourceState.connectorId : destinationState.connectorId,
                connector_credential_id,
                payload
            }).then(res => {
                setLoading(false);
                setOpen(false);

                if (source) {
                    getConnections(sourceState.connectorId, dispatchSource, false);
                    testConnection(sourceState.connectorId, connector_credential_id, dispatchSource)
                } else {
                    getConnections(destinationState.connectorId, dispatchDestination, false);
                    testConnection(destinationState.connectorId, connector_credential_id, dispatchDestination)
                }
            })
        }
    }

    useEffect(() => {
        if (app) {
            if ((source && !app.SourceUploadFLG) || (!source && !app.DestinationDownloadFLG)) {
                setLoading(true);
                ConnectorAPIService.getAuthenticationMethods(app.ConnectorID)
                    .then(({methods} = {}) => {
                        if (methods && methods.length) {
                            setAuthenticationMethods(methods);
                        } else {
                            setAuthenticationMethods([])
                        }
                        setLoading(false);
                    }).catch(() => {
                        console.log("Error getting authentication methods");
                        setLoading(false);
                    })

            }
        }
    }, [app, source]);


    return (
        <>
            <Dialog
                open={open}
                handleClose={() => setOpen(false)}
                title={connector_credential_id ? "Edit Connection" : "New Connection"}
                centerAligned={false}
            >
                {!loading && authenticationMethods.length > 0 ? (
                    <AuthenticationMethod app={app} authenticationMethods={authenticationMethods} saveConnection={saveConnection} loading={loading} connection_nm={connection_nm} connector_default_flg={connector_default_flg} />
                ) : !loading ? (
                    "This connector does not have any authentication methods configured."
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Dialog>
        </>
    )
}

