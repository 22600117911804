import { useState, useEffect, useMemo } from "react";
import { Button } from "@mui/material";
import OauthPopup from "react-oauth-popup";
import GoogleButton from "react-google-button";
// Generate a secure PKCE Code Verifier
const generateRandomBase64String = async (length = 24) => {
    const randomValues = crypto.getRandomValues(new Uint8Array(length));
    return btoa(String.fromCharCode(...randomValues))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, ""); // Convert to base64url format
};

// Compute PKCE Code Challenge from Verifier
const computeCodeChallengeFromVerifier = async (verifier) => {
    const hashedValue = await crypto.subtle.digest(
        "SHA-256",
        new TextEncoder().encode(verifier)
    );
    return btoa(String.fromCharCode(...new Uint8Array(hashedValue)))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, ""); // Convert to base64url format
};

export default function OAuthPopup({config, connectorName, onOpen, onCode, disabled, onError}) {
    const [redirectUri] = useState(window.location.origin + "/oauth");
    const [authUrl, setAuthUrl] = useState(null);
    const [isOpening, setIsOpening] = useState(false);
    const width = useMemo(() => config.oauth_popup_width || 500, [config]);
    const height = useMemo(() => config.oauth_popup_height || 600, [config]);



    // Prepare the auth URL before the click
    useEffect(() => {
        const prepareAuthUrl = async () => {
            try {
                const authorizationUrl = ReplaceConfigVariables(config.authorization_url, config);

                if (!authorizationUrl) {
                    onError("Please set the Authorization URL in the Dev Configuration screen.");
                    return;
                }
                const url = new URL(authorizationUrl);
                
                if (config.response_type) {
                    url.searchParams.append('response_type', config.response_type);
                }
                if (config.client_id) {
                    url.searchParams.append('client_id', config.client_id);
                }
                url.searchParams.append(config.redirect_url_param_name || 'redirect_uri', redirectUri);

                if (config.scopes) {
                    url.searchParams.append('scope', config.scopes);
                }

                if (config.code_challenge_method) {
                    const codeVerifier = await generateRandomBase64String(32);
                    localStorage.setItem("pkce_verifier", codeVerifier);
                    const codeChallenge = await computeCodeChallengeFromVerifier(codeVerifier);
                    url.searchParams.append('code_challenge', codeChallenge);
                    url.searchParams.append('code_challenge_method', 'S256');
                }

                setAuthUrl(url.toString());
            } catch (error) {
                console.error("Error preparing auth URL:", error);
            }
        };

        prepareAuthUrl();
    }, [config, redirectUri]);

    const handleClose = () => {};

    const handleOpen = () => {
        onOpen?.();
    };

    const handleCode = (code, params) => {
        console.log("redirectUri", redirectUri);
        onCode?.({message:"Login successful", success:true, code:code, params:params, redirect_uri:redirectUri});
    }

    // Only render the OauthPopup component when we have a valid authUrl
    if (!authUrl || disabled) {
        return <OAuthButton connectorName={connectorName} disabled={disabled || !authUrl} />; // Return just the trigger button while URL is being prepared
    }


    if (connectorName.includes("Google")) {
        return (
            <OauthPopup
                url={authUrl}
                onCode={handleCode}
                onClose={handleClose}
                width={500}
                height={600}
            >
                <OAuthButton connectorName={connectorName} onClick={handleOpen} />
            </OauthPopup>
        )
    } else {
        return (
            <OauthPopup
                url={authUrl}
                onCode={handleCode}
                onClose={handleClose}
                width={width}
                height={height}
            >
                <OAuthButton connectorName={connectorName} onClick={handleOpen} />

            </OauthPopup>
        )
    }
}

function OAuthButton({connectorName, onClick, disabled}) {
    if (connectorName.includes("Google")) {
        return (
            <GoogleButton
                type="light"
                onClick={onClick}
                style={{ margin: "0 auto" }}
                disabled={disabled}
            />

        )
    }

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={disabled}
        >
            {connectorName} Login
        </Button>
    )
}

function ReplaceConfigVariables(value, config) {
    if (typeof value === 'string') {
        return value.replace(/{(.*?)}/g, (_, key) => config[key] || `{${key}}`);
    }
    return value;
}