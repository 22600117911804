import {
	ArrowDropDownOutlined,
	ArrowDropUpOutlined,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import Settings from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CableIcon from "@mui/icons-material/Cable";
import {
	Avatar,
	Box,
	Button,
	Checkbox,
	ClickAwayListener,
	InputAdornment,
	ListItemIcon,
	Menu,
	MenuItem,
	Paper,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DataConnectorIconNew from "../../assets/data_connector_icon_small.png";
import {
	OrganizationAPIService,
	UserAPIService,
} from "../../config/api-service";
import { APP_KEY, ORGANIZATIONS } from "../../config/constants";
import "../../config/styles.css";
import Dialog from "../common/dialog";
import TimerComponent from "../pipelines/TimerComponent/TimerComponent";
import Register from "./Register";
import "./style.css";
const { REACT_APP_MARKETING_SITE } = process.env;

export default function ButtonAppBar() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
	const [trialEndTime, setTrialEndTime] = React.useState(null);

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("organizationChanged", () => {
				const localStorageData = localStorage.getItem(ORGANIZATIONS);
				if (localStorageData) {
					setTrialEndTime(
						JSON.parse(localStorageData)?.organizationTRL
							?.free_trial_end_dts
					);
				}
			});
		}
	}, []);

	useEffect(() => {
		const localStorageData = localStorage.getItem(ORGANIZATIONS);
		if (JSON.parse(localStorageData)?.organizationTRL?.free_trial_end_dts) {
			setTrialEndTime(
				JSON.parse(localStorageData)?.organizationTRL
					?.free_trial_end_dts
			);
		}
	}, []);

	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// ====================Mobile Menu Start==========================
	const openMobileMenu = Boolean(mobileMenuAnchorEl);

	const handleOpenMobileMenu = (event) => {
		setMobileMenuAnchorEl(event.currentTarget);
	};
	const handleMobileMenuClose = () => {
		setMobileMenuAnchorEl(null);
	};
	// ====================Mobile Menu End=============================

	const handleLogout = async () => {
		await UserAPIService.deleteLogout();
		localStorage.removeItem(APP_KEY);
		localStorage.removeItem(ORGANIZATIONS);

		document.cookie = "uat=; max-age=0";

		if (REACT_APP_MARKETING_SITE !== "unset") {
			window.location.href = `${REACT_APP_MARKETING_SITE}/login`;
		} else {
			window.location.href = `/login`;
		}
	};

	const [openRegisterModal, setOpenRegisterModal] = useState(false);
	const handleRegister = () => {
		setOpenRegisterModal(true);
	};

	const [userInfo, setUserInfo] = useState({});
	const [avatar, setAvatar] = useState({});
	useEffect(() => {
		setAvatar(
			userInfo?.user
				? userInfo?.user?.LastNM
					? userInfo?.user?.FirstNM?.charAt(0) +
					  userInfo?.user?.LastNM?.charAt(0)
					: userInfo?.user?.FirstNM?.charAt(0)
				: ""
		);
	}, [userInfo]);

	const history = useHistory();
	const {
		organizations = [],
		selectedOrganization = "",
		hasPMethods = false,
	} = JSON.parse(localStorage.getItem(ORGANIZATIONS) || "{}");
	const [organization, setOrganization] = useState(
		selectedOrganization || ""
	);
	const [selectedOrganizationName, setSelectedOrganizationName] = useState(
		organizations.find((orga) => orga?.organization_id === organization)
			?.organization_nm || ""
	);
	const [organizationList, setOrganizationList] = useState(
		organizations || []
	);
	const [selectOpen, setSelectOpen] = useState(false);
	const [defaultOrganizationModalOpen, setDefaultOrganizationModalOpen] =
		useState(false);
	const [checkedOrganizationId, setCheckedOrganizationId] = useState("");
	const [checkedOrganizationName, setCheckedOrganizationName] = useState("");
	const [searchOrganizationNameText, setSearchOrganizationNameText] =
		useState("");

	const matches = useMediaQuery("(max-width:599px)");

	const handleSelectOptionClick = () => {
		setSelectOpen((prev) => !prev);
	};

	const handleClickAway = () => {
		setSelectOpen(false);
	};

	const handleSelectOrganization = (id, name) => {
		setOrganization(id);
		setSelectedOrganizationName(name);
	};

	// checkBox start
	const handleSetDefaultOrganization = (organizationId, organizationName) => {
		setCheckedOrganizationId(organizationId);
		setCheckedOrganizationName(organizationName);
		setDefaultOrganizationModalOpen(true);
	};

	const handleChange = async () => {
		const { success = false } =
			await UserAPIService.updateDefaultOrganization(
				userInfo?.user?.DefaultOrganizationID,
				{ DefaultOrganizationID: checkedOrganizationId }
			);
		if (success) {
			handleSelectOrganization(
				checkedOrganizationId,
				checkedOrganizationName
			);
		}
		UserAPIService.getUserDetail()
			.then((res) => {
				localStorage.setItem(
					APP_KEY,
					JSON.stringify({
						user: res?.user,
						permissions: res?.permissionList,
					})
				);
				if (typeof window !== "undefined") {
					window.dispatchEvent(new Event("userUpdated"));
				}
				setDefaultOrganizationModalOpen(false);
			})
			.catch((e) => {
				setDefaultOrganizationModalOpen(false);
			});
	};
	// checkBox end

	useEffect(() => {
		const localStorageUserData = localStorage.getItem(APP_KEY);
		const localStorageOrganizationData =
			localStorage.getItem(ORGANIZATIONS);

		const submitOrganization = async (
			selectedOrganization = "",
			organizations = [],
			DataconnectorAdminFLG,
			InternalFLG
		) => {
			try {
				const assignedOrganization =
					await OrganizationAPIService.assignUserToOrganization(
						selectedOrganization
					);

				if (DataconnectorAdminFLG || InternalFLG) {
					assignedOrganization &&
						localStorage.setItem(
							ORGANIZATIONS,
							JSON.stringify({
								organizations,
								selectedOrganization,
								hasPMethods: true,
								organizationTRL: null,
							})
						);
				} else {
					const methods = await UserAPIService.getPaymentMethod();
					assignedOrganization &&
						methods &&
						localStorage.setItem(
							ORGANIZATIONS,
							JSON.stringify({
								organizations,
								selectedOrganization,
								hasPMethods:
									methods?.paymentMethods?.data?.length > 0
										? true
										: false,
								organizationTRL:
									assignedOrganization.organizationTRL,
							})
						);
				}
			} catch {}
		};

		if (!localStorageUserData || !localStorageOrganizationData) {
			const getOrgs = async () => {
				try {
					OrganizationAPIService.getOrganizations()
						.then((res) => {
							setOrganizationList(res?.organizationList || []);
							setOrganization(res?.user?.DefaultOrganizationID);
							localStorage.setItem(
								APP_KEY,
								JSON.stringify({
									user: res?.user,
									permissions: res?.permissionList,
									anonymousRegistered:
										!res?.user?.EmailVerifiedFLG &&
										!res?.user?.UserAnonymousFlg,
								})
							);
							submitOrganization(
								res?.user?.DefaultOrganizationID,
								res?.organizationList,
								res?.user?.DataconnectorAdminFLG,
								res?.organization?.InternalFLG
							);
						})
						.catch((err) => {});
				} catch (err) {}
			};
			getOrgs();
		} else {
			setUserInfo(JSON.parse(localStorageUserData));
		}
	}, []);

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("userUpdated", () => {
				const localStorageData = localStorage.getItem(APP_KEY);
				if (localStorageData) {
					setUserInfo(JSON.parse(localStorageData));
				} else {
					const getUserDetails = async () => {
						const { user, permissionList } =
							await UserAPIService.getUserDetail();
						setUserInfo({ user, permissions: permissionList });
					};
					getUserDetails();
				}
			});
			window.addEventListener("organizationUpdated", () => {
				const localStorageData = localStorage.getItem(ORGANIZATIONS);
				if (localStorageData) {
					setOrganizationList(
						JSON.parse(localStorageData).organizations
					);
				} else {
					const getOrganizationDetails = async () => {
						const { organizationList = [] } =
							await OrganizationAPIService.getOrganizations();
						setOrganizationList(organizationList);
					};
					getOrganizationDetails();
				}
			});
			window.addEventListener("anonymousUserEventChange", () => {
				const localStorageData = localStorage.getItem(ORGANIZATIONS);
				const localStorageUserData = localStorage.getItem(APP_KEY);
				if (localStorageData) {
					setOrganizationList(
						JSON.parse(localStorageData).organizations
					);
					setOrganization(
						JSON.parse(localStorageData).selectedOrganization
					);
					setSelectedOrganizationName(
						JSON.parse(localStorageData).organizations[0]
							?.organization_nm
					);
				}
				if (localStorageUserData) {
					setUserInfo(JSON.parse(localStorageUserData));
				}
			});
		}
	}, []);

	const onStorageUpdate = (e) => {
		const { key, newValue } = e;
		if (key === APP_KEY) {
			setUserInfo(JSON.parse(newValue));
		}
	};

	useEffect(() => {
		const localStorageUserData = localStorage.getItem(APP_KEY);
		if (localStorageUserData) {
			setUserInfo(JSON.parse(localStorageUserData));
		}
		window.addEventListener("storage", onStorageUpdate);
		return () => {
			window.removeEventListener("storage", onStorageUpdate);
		};
	}, []);

	useEffect(() => {
		const handleAddOrganization = () => {
			const { REACT_APP_MARKETING_SITE = "//" } = process.env;
			const { user: { EmailTXT = "" } = {} } = userInfo;
			window.location.href = `${REACT_APP_MARKETING_SITE}/register-company?editableEmail=${EmailTXT}`;
		};

		if (organization === "handleAddOrganization") {
			handleAddOrganization();
		} else {
			OrganizationAPIService.getOrganizations()
				.then((org) => {
					if (
						org?.user?.DataconnectorAdminFLG ||
						org?.organization?.InternalFLG
					) {
						OrganizationAPIService.assignUserToOrganization(
							organization
						)
							.then((res) => {
								localStorage.setItem(
									ORGANIZATIONS,
									JSON.stringify({
										...JSON.parse(
											localStorage.getItem(
												ORGANIZATIONS
											) || "{}"
										),
										organizationTRL: null,
										selectedOrganization: organization,
									})
								);
								window.dispatchEvent(
									new Event("organizationChanged")
								);
								if (
									res &&
									organization !== selectedOrganization
								) {
									history.go(0);
								}
							})
							.catch((err) => {
								console.log(err);
							});
					} else {
						OrganizationAPIService.assignUserToOrganization(
							organization
						)
							.then((res) => {
								UserAPIService.getPaymentMethod()
									.then((cardRes) => {
										localStorage.setItem(
											ORGANIZATIONS,
											JSON.stringify({
												...JSON.parse(
													localStorage.getItem(
														ORGANIZATIONS
													) || "{}"
												),
												organizationTRL:
													res.organizationTRL,
												hasPMethods:
													cardRes?.paymentMethods
														?.data?.length > 0
														? true
														: false,
											})
										);
										window.dispatchEvent(
											new Event("organizationChanged")
										);
										if (
											res &&
											organization !==
												selectedOrganization
										) {
											history.go(0);
										}
									})
									.catch((e) => {
										console.log(e);
									});
							})
							.catch((err) => console.log(err));
					}
				})
				.catch((err) => console.log(err));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organization]);

	const searchedOrganizationList = organizationList.filter((search) => {
		return search.organization_nm
			.toLowerCase()
			.includes(searchOrganizationNameText.toLowerCase());
	});

	return (
		<>
			{userInfo?.user?.UserAnonymousFlg && (
				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						justifyContent: "space-between",
						alignItems: "center",
						p: { xs: "10px", md: "5px 10px" },
						backgroundColor: "#F96900",
						color: "#fff",
					}}
				>
					<Typography sx={{ fontWeight: 500, textAlign: "center" }}>
						<span
							onClick={handleRegister}
							style={{
								cursor: "pointer",
								textDecoration: "underline",
							}}
						>
							Register
						</span>{" "}
						for a 14-day free trial today to save your progress
					</Typography>
					<Typography sx={{ fontSize: { md: "22px" } }}>
						WELCOME {userInfo?.user?.FirstNM}
					</Typography>
					<Box>
						<Link to="/login">
							<button
								style={{
									marginRight: "10px",
									width: "70px",
									backgroundColor: "#F96900",
									color: "#fff",
									border: "1px solid #fff",
									borderRadius: "5px",
									cursor: "pointer",
									padding: "5px 0",
								}}
							>
								Login
							</button>
						</Link>
						<button
							onClick={handleRegister}
							style={{
								width: "70px",
								backgroundColor: "#F96900",
								color: "#fff",
								border: "1px solid #fff",
								borderRadius: "5px",
								cursor: "pointer",
								padding: "5px 0",
							}}
						>
							Register
						</button>
					</Box>
				</Box>
			)}
			{userInfo?.anonymousRegistered &&
				!userInfo?.user?.EmailVerifiedFLG && (
					<Box
						sx={{
							p: { xs: "10px", md: "5px 10px" },
							backgroundColor: "#F96900",
							color: "#fff",
						}}
					>
						<Typography
							sx={{
								fontWeight: 500,
								textAlign: { xs: "center", md: "left" },
							}}
						>
							Welcome {userInfo?.user?.EmailTXT} to Data
							Connector! Please check your email to complete
							registration.
						</Typography>
					</Box>
				)}
			{openRegisterModal && (
				<Register
					openRegisterModal={openRegisterModal}
					setOpenRegisterModal={setOpenRegisterModal}
				/>
			)}
			<Box
				component={"nav"}
				sx={{
					height: { xs: "auto", sm: "auto", md: "54px" },
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					alignItems: { xs: "stretch", md: "center" },
					justifyContent: "space-between",
					backgroundColor: "#fff",
					boxShadow:
						"0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.1)",
				}}
			>
				{/* <Box component={"nav"} className="topnav"> */}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<img
						src={DataConnectorIconNew}
						width="70px"
						height="auto"
						alt="Data Connector"
					/>
					<ClickAwayListener onClickAway={handleClickAway}>
						<Box
							sx={{
								position: "relative",
								width: { xs: 200, sm: 325 },
								ml: { xs: 0, sm: 2 },
							}}
						>
							<TextField
								size="small"
								variant="outlined"
								sx={{
									width: { xs: 200, sm: 325 },
									"& label.Mui-focused": {
										color: "#56B2E3",
									},
									"& .MuiInput-underline:after": {
										borderBottomColor: "#56B2E3",
									},
									"& .MuiOutlinedInput-input": {
										cursor: "default",
									},
									"& .MuiOutlinedInput-root": {
										"&.Mui-focused fieldset": {
											borderColor: "#56B2E3",
										},
										cursor: "default",
									},
								}}
								label="Organization"
								onClick={handleSelectOptionClick}
								value={
									!matches &&
									selectedOrganizationName.length > 30
										? selectedOrganizationName.slice(
												0,
												30
										  ) + "..."
										: matches &&
										  selectedOrganizationName.length > 15
										? selectedOrganizationName.slice(
												0,
												15
										  ) + "..."
										: selectedOrganizationName
								}
								InputProps={{
									readOnly: true,
									endAdornment: (
										<InputAdornment
											position="end"
											sx={{ cursor: "default" }}
										>
											{selectOpen ? (
												<ArrowDropUpOutlined />
											) : (
												<ArrowDropDownOutlined />
											)}
										</InputAdornment>
									),
								}}
							/>
							{selectOpen ? (
								<Paper
									sx={{
										position: "absolute",
										top: 40,
										right: 0,
										left: 0,
										zIndex: 99,
										border: "1px solid #CDD2D7",
										borderRadius: "5px",
										background: "background.paper",
										maxHeight: 350,
										cursor: "default",
									}}
									elevation={0}
								>
									<Box sx={{ mb: 1, mt: 2, mx: 2 }}>
										<TextField
											size="small"
											variant="standard"
											fullWidth
											placeholder="Search Organization"
											sx={{ ml: "-5px" }}
											onChange={(e) =>
												setSearchOrganizationNameText(
													e.target.value
												)
											}
										/>
									</Box>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											px: 1,
										}}
									>
										<Typography>Account</Typography>
										<Typography sx={{ pr: 1.5 }}>
											Default
										</Typography>
									</Box>
									<Box
										sx={{
											overflow: "overlay",
											maxHeight: 250,
										}}
									>
										{searchedOrganizationList?.map(
											({
												organization_nm,
												organization_id,
											}) => (
												<Box
													key={organization_id}
													sx={{
														display: "flex",
														justifyContent:
															"space-between",
														alignItems: "center",
														backgroundColor:
															organization ===
																organization_id &&
															"rgba(0, 191, 255, 0.08)",
														":hover": {
															backgroundColor:
																"rgba(0, 0, 0, 0.04)",
														},
													}}
													onClick={handleClickAway}
												>
													{/* <Tooltip title={organization_nm} placement="top"> */}
													<Typography
														onClick={() =>
															handleSelectOrganization(
																organization_id,
																organization_nm
															)
														}
														sx={{
															width: "100%",
															textAlign: "left",
															pl: 1,
															py: "5px",
														}}
													>
														{!matches &&
														organization_nm.length >
															30
															? organization_nm.slice(
																	0,
																	30
															  ) + "..."
															: matches &&
															  organization_nm.length >
																	12
															? organization_nm.slice(
																	0,
																	12
															  ) + "..."
															: organization_nm}
													</Typography>
													{/* </Tooltip> */}
													<Checkbox
														sx={{
															padding: 0,
															pr: "32px",
														}}
														checked={
															userInfo?.user
																?.DefaultOrganizationID ===
															organization_id
														}
														onChange={() =>
															handleSetDefaultOrganization(
																organization_id,
																organization_nm
															)
														}
													/>
												</Box>
											)
										)}
									</Box>
									<Box onClick={handleClickAway}>
										<Button
											variant="contained"
											fullWidth
											handleAddOrganization
											onClick={() =>
												handleSelectOrganization(
													"handleAddOrganization",
													selectedOrganizationName
												)
											}
											sx={{
												backgroundColor: "#56B2E3",
												borderTop: "none",
												borderRadius: "0 0 3px 3px",
												color: "#fff",
												"&:hover": {
													backgroundColor: "#56B2E3",
												},
												cursor: "pointer",
												textAlign: "center",
											}}
										>
											ADD ORGANIZATION
										</Button>
									</Box>
								</Paper>
							) : null}
						</Box>
					</ClickAwayListener>
					<Box
						className="nav-2"
						sx={{ display: { xs: "block", md: "none" } }}
					>
						{/* <Box className='nav-2'> */}
						<span onClick={handleOpenMobileMenu}>
							<MenuIcon
								fontSize="large"
								sx={{ color: "var(--data-con-blue)" }}
							/>
						</span>
					</Box>

					<Box sx={{ display: { md: "block", xs: "none" } }}>
						{!hasPMethods && trialEndTime && (
							<TimerComponent trialEndTime={trialEndTime} />
						)}
					</Box>
				</Box>
				<Box sx={{ display: { xs: "block", md: "none" } }}>
					{!hasPMethods && trialEndTime && (
						<TimerComponent trialEndTime={trialEndTime} />
					)}
				</Box>

				<Box sx={{ display: { xs: "none", md: "block" } }}>
					{/* <Box className="nav-links" sx={{ display: { xs: "none", md: "block" } }}> */}
					<Link to="/" className="nav-link">
						Home
					</Link>
					{userInfo?.user?.UserAnonymousFlg && (
						<span
							onClick={() =>
								window.location.assign(
									`${REACT_APP_MARKETING_SITE}/product`
								)
							}
							className="nav-link"
							style={{ cursor: "pointer" }}
						>
							Product
						</span>
					)}
					{userInfo?.user?.UserAnonymousFlg && (
						<span
							onClick={() =>
								window.location.assign(
									`${REACT_APP_MARKETING_SITE}/pricing`
								)
							}
							className="nav-link"
							style={{ cursor: "pointer" }}
						>
							Pricing
						</span>
					)}
					<Link to="/" className="nav-link">
						Pipelines
					</Link>
					{/* <Link to="/resources" className="nav-link">
                        Resources
                    </Link> */}
					<Link to="/support" className="nav-link">
						Support
					</Link>

					{userInfo?.user?.DataconnectorAdminFLG === 1 && (
						<Link to="/connectors" className="nav-link">
							Connectors
						</Link>
					)}

					{/* <Link to="/" className="nav-link">
                        Admin
                    </Link> */}
				</Box>
				<Box
					className="nav-2"
					sx={{ display: { xs: "none", md: "block" } }}
				>
					{/* <Box className='nav-2'> */}
					<span className="nav-link" onClick={handleClick}>
						<Avatar>{`${avatar}`}</Avatar>
					</span>
				</Box>
			</Box>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.25,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				{userInfo?.permissions?.some(
					({ permission_nm }) => permission_nm === "Admin"
				) && window.location.href.includes("localhost") ? (
					<MenuItem>
						<Link to="/admin" className="regular-link">
							<ListItemIcon>
								<AccountCircleIcon fontSize="small" />
							</ListItemIcon>
							Admin
						</Link>
					</MenuItem>
				) : null}
				<MenuItem>
					<Link to="/account" className="regular-link">
						<ListItemIcon>
							<Settings fontSize="small" />
						</ListItemIcon>
						Settings
					</Link>
				</MenuItem>
				{/* <MenuItem>
                    <Link to='/documentation' className='regular-link'>
                        <ListItemIcon>
                            <HelpIcon fontSize="small" />
                        </ListItemIcon>
                        Help
                    </Link>
                </MenuItem> */}
				<MenuItem
					sx={{
						display:
							(userInfo?.user?.UserAnonymousFlg ||
								userInfo?.anonymousRegistered) &&
							!userInfo?.user?.EmailVerifiedFLG
								? "none"
								: "block",
					}}
				>
					<Link
						to="/#"
						className="regular-link"
						onClick={handleLogout}
					>
						<ListItemIcon>
							<Logout fontSize="small" />
						</ListItemIcon>
						Logout
					</Link>
				</MenuItem>
			</Menu>

			{/* ======================Mobile Menu Start==================== */}
			<Menu
				anchorEl={mobileMenuAnchorEl}
				open={openMobileMenu}
				onClose={handleMobileMenuClose}
				onClick={handleMobileMenuClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1,
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 7,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<MenuItem>
					<Link to="/" className="regular-link">
						<ListItemIcon>
							<HomeIcon fontSize="small" />
						</ListItemIcon>
						Home
					</Link>
				</MenuItem>
				<MenuItem>
					<Link to="/" className="regular-link">
						<ListItemIcon>
							<HomeIcon fontSize="small" />
						</ListItemIcon>
						Pipelines
					</Link>
				</MenuItem>
				<MenuItem>
					<Link to="/support" className="regular-link">
						<ListItemIcon>
							<SupportAgentIcon fontSize="small" />
						</ListItemIcon>
						Support
					</Link>
				</MenuItem>
				<MenuItem>
					<Link to="/connectors" className="regular-link">
						<ListItemIcon>
							<CableIcon fontSize="small" />
						</ListItemIcon>
						Connectors
					</Link>
				</MenuItem>

				{userInfo?.permissions?.some(
					({ permission_nm }) => permission_nm === "Admin"
				) && window.location.href.includes("localhost") ? (
					<MenuItem>
						<Link to="/admin" className="regular-link">
							<ListItemIcon>
								<AccountCircleIcon fontSize="small" />
							</ListItemIcon>
							Admin
						</Link>
					</MenuItem>
				) : null}
				<MenuItem>
					<Link to="/account" className="regular-link">
						<ListItemIcon>
							<Settings fontSize="small" />
						</ListItemIcon>
						Settings
					</Link>
				</MenuItem>
				<MenuItem
					sx={{
						display:
							(userInfo?.user?.UserAnonymousFlg ||
								userInfo?.anonymousRegistered) &&
							!userInfo?.user?.EmailVerifiedFLG
								? "none"
								: "block",
					}}
				>
					<Link
						to="/#"
						className="regular-link"
						onClick={handleLogout}
					>
						<ListItemIcon>
							<Logout fontSize="small" />
						</ListItemIcon>
						Logout
					</Link>
				</MenuItem>
			</Menu>
			{/* ======================Mobile Menu End==================== */}

			<Dialog
				open={defaultOrganizationModalOpen}
				handleClose={() => setDefaultOrganizationModalOpen(false)}
				title="Confirm Action"
				handleConfirm={handleChange}
			>
				You are about to make{" "}
				<span style={{ fontWeight: "bold" }}>
					{checkedOrganizationName}
				</span>{" "}
				the default organization. Are you sure you want to make this
				change?
			</Dialog>
		</>
	);
}
