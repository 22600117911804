import { useEffect, useState, useRef } from "react";
import {
	Box,
	Divider,
	Button,
	Grid,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
	Skeleton,
	Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConnectorModalContext } from "../../connectors.context";
import { ConnectorAPIService } from "../../../../config/api-service";
import { toast } from "react-toastify";
import DynamicTestUserForm from "./DynamicTestUserForm";
import { AuthenticationType } from "../../../authentication";

const TestUserAccounts = () => {
	const [testUserAccountsInfo, setTestUserAccountsInfo] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [authTypes, setAuthTypes] = useState([]);
	const [selectedAuthType, setSelectedAuthType] = useState(""); // Track the selected value
	const [testUserAccData, setTestUserAccData] = useState();
	const [loading, setLoading] = useState(false);
	const [authId, setAuthId] = useState();
	const [authFields, setAuthFields] = useState();
	const [localUserCount, setLocalUserCount] = useState(0); // Local count for new users
	const [showNewUser, setShowNewUser] = useState(false);
	const [authMethods, setAuthMethods] = useState([]);
	const testUserScollRef = useRef(null);
	const newConnectionScollRef = useRef(null);

	const { selectedConnector, newAuthTypeAdded } = useConnectorModalContext();

	useEffect(() => {
		setIsLoading(true);

		ConnectorAPIService.getConnectorAuthenticationTypes(
			selectedConnector.ConnectorID
		)
			.then((res) => {
				res.authenticationDetailList.sort((a, b) =>
					a.authentication_type_name.localeCompare(
						b.authentication_type_name
					)
				);
				setAuthTypes(res.authenticationDetailList);

				if (res.authenticationDetailList.length > 0) {
					handleAuthTypeChange({ target: { value: res.authenticationDetailList[0].authentication_type_id } });
				}
			})
			.catch(() => {
				setIsLoading(false);
			});

		ConnectorAPIService.getAuthenticationMethods(selectedConnector.ConnectorID)
			.then(({methods} = {}) => {
				if (methods && methods.length) {
					setAuthMethods(methods);
				} else {
					setAuthMethods([])
				}
			}).catch(() => {
				console.log("Error getting authentication methods");
			})
	}, [selectedConnector.ConnectorID, newAuthTypeAdded]);

	const handleToggleNewConnection = async () => {
		const newUser = !showNewUser;
		setShowNewUser(newUser);

		setTimeout(() => {
			if (newUser && newConnectionScollRef.current) {
				newConnectionScollRef.current.scrollIntoView({ 
					behavior: "smooth",
					block: "start"  // Ensures it scrolls to the top of the element
				});
			} else if (!newUser && testUserScollRef.current) {
				testUserScollRef.current.scrollIntoView({ 
					behavior: "smooth",
					block: "start"  // Ensures it scrolls to the top of the element
				});
			}
		}, 100);

	};


	const handleAuthTypeChange = async (e) => {
		const selectedAuthType = e.target.value;
		setSelectedAuthType(selectedAuthType); 

		if (!selectedAuthType) {
			setTestUserAccData([]);
			return;
		}
		setIsLoading(true);

		setAuthId(selectedAuthType);
		try {
			const data = await ConnectorAPIService.getConnectorTestUser(
				selectedConnector.ConnectorID,
				selectedAuthType
			);
			setTestUserAccountsInfo([]);
			setTestUserAccData(data);
		} catch (error) {
			console.error("Error fetching test user data:", error);
			setTestUserAccData([]);
		} finally {
			setIsLoading(false); 
		}
	};

	const handleSave = async (connectorCredentialId) => {
		setLoading(true);
	
		try {
			const response = await ConnectorAPIService.saveConnectorCredential(
				selectedConnector.ConnectorID,
				connectorCredentialId
			);
			if (response) {
				toast.success("User created successfully");
	
				const updatedData =
					await ConnectorAPIService.getConnectorTestUser(
						selectedConnector.ConnectorID,
						selectedAuthType
					);
	
				setTestUserAccData(updatedData);

				handleToggleNewConnection();
			}
		} catch (error) {
			toast.error("Failed to save user");

			console.error("Error saving user:", error);
		} finally {
			setLoading(false);
		}
	};
	

	const handleRemoveTestUser = (index) => {
		setTestUserAccountsInfo((prev) => prev.filter((_, i) => i !== index));
		setLocalUserCount((prev) => prev - 1);
	};

	const handleFieldChange = (e, userIndex, fieldIndex) => {
		const { value } = e.target;
		setTestUserAccountsInfo((prev) => {
			const updatedUsers = [...prev];
			updatedUsers[userIndex].authFields[fieldIndex].field_value_dsc = value;
	
			// Update the error property based on the value
			updatedUsers[userIndex].authFields[fieldIndex].error = !value.trim(); // true if blank, false otherwise
	
			return updatedUsers;
		});
	};

	return (
		<>
			<Divider
				ref={testUserScollRef}
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				Test User Accounts
			</Divider>
			<Box sx={{ mt: 5, textAlign: "center" }}>
				<FormControl size="small">
					<InputLabel id={`verification_type`}>
						Connection Type
					</InputLabel>
					<Select
						labelId={`verification_type`}
						value={selectedAuthType || ""}
						onChange={(e) => handleAuthTypeChange(e)}
						label="Connection Type"
						sx={{
							minWidth: { xs: 230, md: 250 },
						}}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{authTypes.map((authType) => (
							<MenuItem
								key={authType.authentication_type_id}
								value={authType.authentication_type_id}
							>
								{authType.authentication_type_name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			{isLoading ? (
				<Box sx={{ padding: 4 }}>
					<Skeleton
						variant="rectangular"
						height={50}
						sx={{ marginBottom: 2 }}
					/>
					<Skeleton
						variant="rectangular"
						height={50}
						sx={{ marginBottom: 2 }}
					/>
					<Skeleton
						variant="rectangular"
						height={50}
						sx={{ marginBottom: 2 }}
					/>
				</Box>
			) : (<DynamicTestUserForm data={testUserAccData} setData={setTestUserAccData} />)}


			<Box sx={{ textAlign: "center", py: 4 }}>
				<Button
					variant="contained"
					sx={{
						height: 40,
						minWidth: 150,
						backgroundColor: "var(--data-con-blue)",
					}}
					onClick={handleToggleNewConnection}
					disabled={!selectedAuthType}
				>
					{!showNewUser ? "ADD TEST USER" : "CANCEL"}
				</Button>
			</Box>
			
			{showNewUser && (
				<Box
					ref={newConnectionScollRef}
				sx={{
					border: "1px solid lightgray",
					my: 2,
					borderRadius: "5px",
					position: "relative",
					p: 4,
				}}
			>
				<Box
					sx={{
						position: "absolute",
						top: "-10px",
						left: "16px",
						backgroundColor: "white",
						padding: "0 8px",
						fontSize: "14px",
						fontWeight: "bold",
						color: "gray",
					}}
				>
					New Connection
				</Box>
				<IconButton
						disableRipple
						sx={{
							position: "absolute",
							top: "-5px",
							right: "-5px",
						}}
						onClick={() => setShowNewUser(false)}
					>
					<DeleteIcon />
				</IconButton>
				<AuthenticationType authenticationMethods={authMethods} app={selectedConnector} saveConnection={handleSave} mode="test" nextUserId={testUserAccData.length + 1} />
				</Box>
			)}
		</>


	);
};

export default TestUserAccounts;
