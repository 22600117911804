import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export function CommonSelect({key, label, value, onChange, options, optionKey, optionValue}) {
    return (
        <FormControl 
            fullWidth
            size="small">
            <InputLabel id={key + "-label"}>{label}</InputLabel>
            <Select
                labelId={key + "-label"}
                id={key + "-select"}
                value={value}
                label={label}
                onChange={onChange}
            >
                {options.map((option) => (
                    <MenuItem value={option[optionKey]}>{option[optionValue]}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}