import moment from "moment";
import { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { APP_KEY, ORGANIZATIONS } from "../../../config/constants";
import { OrganizationContext } from "../../../context/organization.context";

export default function PrivateRoute({ children, ...rest }) {
	const { setIsActiveUser } = useContext(OrganizationContext);
	const {
		selectedOrganization,
		organizations,
		hasPMethods,
		organizationTRL,
	} = JSON.parse(localStorage.getItem(ORGANIZATIONS) || "{}");

	let route = "/login";
	let isActiveUser = false;

	if (selectedOrganization && organizations?.length) {
		const sectectedOrg = organizations?.find(
			(org) => org.organization_id === selectedOrganization
		);
		const dataConnectorUser = JSON.parse(localStorage.getItem(APP_KEY));

		if (dataConnectorUser.user.DataconnectorAdminFLG) {
			isActiveUser = true;
		} else {
			isActiveUser = !!sectectedOrg?.active_flg;
		}

		if (organizationTRL) {
			if (new Date(organizationTRL?.free_trial_end_dts) < new Date()) {
				if (
					!hasPMethods &&
					sectectedOrg.main_contact_user_id ===
						dataConnectorUser.user.UserID
				) {
					route = "/payment-method-alert";
				} else {
					route = "/membership-alert";
				}
			} else {
				route = "/membership-alert";
			}
		}
	}

	useEffect(() => {
		setIsActiveUser(isActiveUser);
	}, [isActiveUser]);

	return (
		<Route {...rest}>
			{(isActiveUser && hasPMethods) ||
			new Date(organizationTRL?.free_trial_end_dts) > new Date() ||
			!organizationTRL ? (
				children
			) : (
				<Redirect to={route} />
			)}
		</Route>
	);
}
