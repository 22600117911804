import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Skeleton,
	CardMedia,
	Checkbox,
	FormControlLabel,
	FormHelperText
} from "@mui/material";
import { useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../../config/api-service";
import AlertDialog from "../../../common/dialog";
import { useConnectorModalContext } from "../../connectors.context";
import AuthenticationFieldLoader from "./AuthenticationFieldLoader";

import AuthenticationLoader from "./AuthenticationLoader";
import { sectionStatusImageMapper } from "../ConnectorInfo";
import AuthTypeAddModal from "./AuthTypeAddModal";

const changeColor = (defaultInput, userInput) => {
	return userInput !== null && userInput !== defaultInput;
};

const fieldScopes = [
	{
		field_scope_id: "1",
		field_scope_nm: "APP_CONFIG"
	},
	{
		field_scope_id: "2",
		field_scope_nm: "APP_CREDENTIAL"
	},
	{
		field_scope_id: "3",
		field_scope_nm: "USER_INPUT"
	},
	{
		field_scope_id: "4",
		field_scope_nm: "ADVANCED_INPUT"
	},
	{
		field_scope_id: "5",
		field_scope_nm: "INTERACTIBLE_INPUT"
	},
	{
		field_scope_id: "6",
		field_scope_nm: "CONNECTOR_SET"
	}
]

const fieldDataTypes = [
	[
		"JSON string array",
		"JSON array",
		"JSON object",
		"string date",
		"string phone number",
		"string email",
		"string url",
		"string",
		"number",
		"boolean",
		"any"
	],
	[
		"text",
		"password",
		"checkbox",
		"radio",
		"select",
		"button"
	],
]


const Authentication = () => {
	const [authenticationInfo, setAuthenticationInfo] = useState({});
	const [activeTask, setActiveTask] = useState(null);
	const [authTypes, setAuthTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [authFields, setAuthFields] = useState([]);
	const [isFieldLoading, setIsFieldLoading] = useState(false);
	const [authType, setAuthType] = useState(null);
	const [showFieldAddModal, setShowFieldAddModal] = useState(false);
	const [isAddFieldLoading, setIsAddFieldLoading] = useState(false);
	const [fieldDetails, setFieldDetails] = useState({connector_specific_flg: 1});
	const [fieldNameError, setFieldNameError] = useState(false);
	const [fieldDataTypeError, setFieldDataTypeError] = useState(false);
	const [fieldScopeError, setFieldScopeError] = useState(false);
	const [showAuthTypeAddModal, setShowAuthTypeAddModal] = useState(false);
	const [initialRender, setInitialRender] = useState(true);
	const [apiInfoStatus, setApiInfoStatus] = useState(null);

	const [imageRotation, setImageRotation] = useState(0);

	const {
		selectedConnector,
		activeTaskList,
		newAuthTypeAdded,
		sectionStatusList,
		isSectionStatusImageLoading,
	} = useConnectorModalContext();

	useEffect(() => {
		const task = activeTaskList?.find(
			(activeTask) => activeTask.prime_jira_task_id === 7
		);
		setActiveTask(task);
	}, [activeTaskList]);

	useEffect(() => {
		const apiInfo = sectionStatusList.find(
			(section) =>
				section?.field_mapping_table_nm ===
				"connector_authentication_type"
		);
		apiInfo && setApiInfoStatus(apiInfo.section_status);
	}, [sectionStatusList]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setImageRotation(imageRotation + 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [imageRotation]);

	useEffect(() => {
		setIsLoading(true);

		ConnectorAPIService.getConnectorAuthenticationTypes(
			selectedConnector.ConnectorID
		)
			.then((res) => {
				res.authenticationDetailList.sort(function (a, b) {
					return a.authentication_type_name.localeCompare(
						b.authentication_type_name
					);
				});
				setAuthTypes(res.authenticationDetailList);

				if (initialRender) {
					handleAuthTypeChange({
						target: {
							value: res?.authenticationDetailList?.[0]
								?.authentication_type_id,
						},
					});
					setInitialRender(false);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, newAuthTypeAdded]);

	const handleAuthTypeChange = async (e) => {
		setIsFieldLoading(true);
		setAuthenticationInfo({});
		setAuthType(e.target.value);
		if (!e.target.value) {
			setAuthFields([]);
			setIsFieldLoading(false);
			return;
		}

		ConnectorAPIService.getConnectorAuthenticationTypeFields(
			selectedConnector.ConnectorID,
			e.target.value,
			"1"
		)
			.then((res) => {
				setAuthFields(res.authenticationTypeFieldList);
				setIsFieldLoading(false);
			})
			.catch((err) => {
				setIsFieldLoading(false);
			});
	};

	const handleFieldAddModalClose = () => {
		setShowFieldAddModal(false);
		setFieldDetails({connector_specific_flg: 1});
		setFieldNameError(false);
		setFieldDataTypeError(false);
		setFieldScopeError(false);
	};


	const handleAddField = async () => {
		setFieldNameError(!fieldDetails?.field_nm);
		setFieldDataTypeError(!fieldDetails?.field_data_type_txt);
		setFieldScopeError(!fieldDetails?.field_scope_id);
		if (!fieldDetails?.field_nm || !fieldDetails?.field_data_type_txt || !fieldDetails?.field_scope_id) {
			return;
		}

		try {
			setIsAddFieldLoading(true);
			const res =
				await ConnectorAPIService.createConnectorAuthenticationTypeField(
					selectedConnector.ConnectorID,
					authType,
					fieldDetails
				);

			if (res.success) {
				const fields =
					await ConnectorAPIService.getConnectorAuthenticationTypeFields(
						selectedConnector.ConnectorID,
						authType,
						"1"
					);

				if (fields) {
					setAuthFields(fields.authenticationTypeFieldList);
					setIsAddFieldLoading(false);
					setShowFieldAddModal(false);
					setFieldDetails({connector_specific_flg: 1});
				}
			}

		} catch {
			setIsAddFieldLoading(false);
		}
	};

	const updateAuthenticationField = async (e) => {
		try {
			if (!authenticationInfo?.authentication_type_field_id) return;
			if (e.target.value.trim() === "") {
				return;
			}

			await ConnectorAPIService.updateConnectorAuthenticationTypeField(
				selectedConnector.ConnectorID,
				authenticationInfo.authentication_type_field_id,
				{
					field_value_user_input_dsc:
						authenticationInfo.field_value_user_input_dsc,
				}
			);
		} catch {}
	};

	return (
		<>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
				{isSectionStatusImageLoading ? (
					<Grid>
						<Skeleton variant="circular" width={40} height={40} />
					</Grid>
				) : (
					sectionStatusImageMapper[apiInfoStatus] && (
						<Tooltip title={apiInfoStatus}>
							<CardMedia
								component={"img"}
								alt="status"
								image={sectionStatusImageMapper[apiInfoStatus]}
								sx={{
									width: 40,
									height: 40,
									rotate:
										apiInfoStatus === "In Progress"
											? `${imageRotation * 45}deg`
											: "0deg",
								}}
							/>
						</Tooltip>
					)
				)}

				<Divider
					sx={{
						textTransform: "uppercase",
						fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
						width: "100%",
					}}
				>
					AUTHENTICATION
				</Divider>
			</Box>
			{isLoading ? (
				<AuthenticationLoader />
			) : (
				<>
					<Box
						sx={{
							mt: 4,
							display: "flex",
							justifyContent: "center",
							gap: "1rem",
						}}
					>
						<FormControl size="small">
							<InputLabel id="auth_type">Auth Type</InputLabel>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<Select
									disabled={activeTask}
									labelId="auth_type"
									value={authType}
									onChange={(e) => handleAuthTypeChange(e)}
									label="Auth Type"
									sx={{
										minWidth: 200,
									}}
								>
									<MenuItem value={""}>
										<em>None</em>
									</MenuItem>
									{authTypes.map((authType) => (
										<MenuItem
											key={
												authType.authentication_type_id
											}
											value={
												authType.authentication_type_id
											}
										>
											{authType.authentication_type_name}
										</MenuItem>
									))}
								</Select>
							</Tooltip>
						</FormControl>
						<Button
							variant="contained"
							sx={{
								height: 40,
								backgroundColor: "var(--data-con-blue)",
							}}
							onClick={() => setShowAuthTypeAddModal(true)}
						>
							ADD AUTH TYPE
						</Button>
					</Box>
					<Grid container spacing={2} sx={{ py: 4 }}>
						{isFieldLoading ? (
							<AuthenticationFieldLoader />
						) : (
							authFields
								.filter(
									(authField) =>
										authField?.field_scope_id === "1"
								)
								.map((authField) => (
									<Grid
										item
										xs={12}
										md={6}
										sx={{ gap: 1 }}
										key={
											authField?.authentication_type_field_id
										}
									>
										<Tooltip
											title={
												activeTask
													? `You can not update this field when the task: ${activeTask?.name} is in an active state`
													: ""
											}
											placement="top"
										>
											<TextField
												disabled={activeTask}
												label={ authField?.field_nm }
												variant="outlined"
												size="small"
												value={
													authField?.field_value_user_input_dsc ||
													authField?.field_value_dsc
												}
												defaultValue={
													authField?.field_value_user_input_dsc ||
													authField?.field_value_dsc
												}
												onChange={(e) => {
													console.log(authField, 'authField');
													setAuthenticationInfo({
														authentication_type_field_id:
															authField.authentication_type_field_id,
														field_value_user_input_dsc:
															e.target.value ||
															null,
													});

													const newAuthFields =
														authFields.map(
															(field) => {
																if (
																	field.authentication_type_field_id ===
																	authField.authentication_type_field_id
																) {
																	field[
																		"field_value_user_input_dsc"
																	] =
																		e.target
																			.value ||
																		null;
																}
																return field;
															}
														);

													setAuthFields(
														newAuthFields
													);
												}}
												onBlur={
													updateAuthenticationField
												}
												InputLabelProps={{
													shrink: true,
													sx: {
														color:
															changeColor(
																authField?.field_value_dsc,
																authField?.field_value_user_input_dsc
															) &&
															"var(--data-con-blue)",
													},
												}}
												InputProps={{
													sx: {
														height: 37,
														padding: 1,
													},
													inputProps: {
														sx: {
															padding: 0,
															height: "100%",
															display: "flex",
															alignItems:
																"center",
															fontSize:
																"0.875rem",
														},
													},
												}}
												sx={{
													width: "100%",
													padding: 0,
													".MuiOutlinedInput-notchedOutline":
														changeColor(
															authField?.field_value_dsc,
															authField?.field_value_user_input_dsc
														) && {
															borderColor:
																"var(--data-con-blue)",
														},
												}}
											/>
										</Tooltip>
									</Grid>
								))
						)}
					</Grid>

					{authType && (
						<Box sx={{ textAlign: "center", mb: 4 }}>
							<Button
								variant="contained"
								sx={{
									height: 40,
									minWidth: 150,
									backgroundColor: "var(--data-con-blue)",
								}}
								onClick={() => setShowFieldAddModal(true)}
							>
								ADD FIELD
							</Button>
						</Box>
					)}
					<AlertDialog
						open={showFieldAddModal}
						handleClose={handleFieldAddModalClose}
						actions={
							<Box sx={{ mt: 2, textAlign: "right" }}>
								<LoadingButton
									onClick={handleAddField}
									loading={isAddFieldLoading}
									variant="contained"
								>
									Save
								</LoadingButton>
							</Box>
						}
						title="Add Field"
						centerAligned={false}
						maxWidth={"md"}
					>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<TextField
									required
									label="Field Name"
									variant="outlined"
									value={fieldDetails?.field_nm}
									onChange={(e) => {
										if (!e.target.value) {
											setFieldNameError(true);
										} else {
											setFieldNameError(false);
										}
										setFieldDetails((prev) => ({
											...prev,
											field_nm: e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
									error={fieldNameError}
									helperText={
										fieldNameError &&
										"Field name is required"
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<TextField
									label="Field Value"
									variant="outlined"
									value={fieldDetails?.field_value_dsc}
									onChange={(e) => {
										setFieldDetails((prev) => ({
											...prev,
											field_value_dsc: e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<TextField
									label="Default Value"
									variant="outlined"
									value={fieldDetails?.default_value_txt}
									onChange={(e) => {
										setFieldDetails((prev) => ({
											...prev,
											default_value_txt: e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<TextField
									label="Placeholder Value"
									variant="outlined"
									value={fieldDetails?.placeholder_value_txt}
									onChange={(e) => {
										setFieldDetails((prev) => ({
											...prev,
											placeholder_value_txt: e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<TextField
									label="End Adornment"
									variant="outlined"
									value={fieldDetails?.end_adornment_txt}
									onChange={(e) => {
										setFieldDetails((prev) => ({
											...prev,
											end_adornment_txt: e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<TextField
									label="User Friendly Label"
									variant="outlined"
									value={fieldDetails?.user_friendly_label}
									onChange={(e) => {
										setFieldDetails((prev) => ({
											...prev,
											user_friendly_label: e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<TextField
									label="Allowed Values (Comma Separated)"
									variant="outlined"
									value={fieldDetails?.allowed_values_txt}
									onChange={(e) => {
										setFieldDetails((prev) => ({
											...prev,
											allowed_values_txt: e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<FormControl size="small" required error={fieldScopeError}>
									<InputLabel id="field_scope_id">Field Scope</InputLabel>
									<Select
											labelId="field_scope_id"
											value={fieldDetails?.field_scope_id}
											onChange={(e) => {
												if (!e.target.value) {
													setFieldScopeError(true);
												} else {
													setFieldScopeError(false);
												}
												setFieldDetails((prev) => ({
													...prev,
													field_scope_id: e.target.value,
												}));
											}}
											label="Field Scope"
											sx={{
												minWidth: 200,
											}}
											size="small"
										>
										<MenuItem value={""}>
											<em>None</em>
										</MenuItem>
										{fieldScopes.map((scope) => (
											<MenuItem
												key={
													scope.field_scope_id
												}
												value={
													scope.field_scope_id
												}
											>
												{scope.field_scope_nm}
											</MenuItem>
										))}
									</Select>
									<FormHelperText error={fieldScopeError}>{fieldScopeError && "Scope is required"}</FormHelperText>
								</FormControl>
							</Grid>
							{fieldDetails?.field_scope_id && (
								<Grid item xs={12} md={6} sx={{ gap: 1 }}>
									<FormControl size="small" required error={fieldDataTypeError}>
										<InputLabel id="field_data_type_txt">Field Data Type</InputLabel>
										<Select
												labelId="field_data_type_txt"
												value={fieldDetails?.field_data_type_txt}
												onChange={(e) => {
													if (!e.target.value) {
														setFieldDataTypeError(true);
													} else {
														setFieldDataTypeError(false);
													}
													setFieldDetails((prev) => ({
														...prev,
														field_data_type_txt: e.target.value,
													}));
												}}
												label="Field Data Type"
												sx={{
													minWidth: 200,
												}}
												size="small"
											>
											<MenuItem value={""}>
												<em>None</em>
											</MenuItem>
											{fieldDataTypes[fieldDetails?.field_scope_id === "1" ? 0 : 1].map((dataType) => (
												<MenuItem
													key={
														dataType
													}
													value={
														dataType
													}
												>
													{dataType}
												</MenuItem>
											))}
										</Select>
										<FormHelperText error={fieldDataTypeError}>{fieldDataTypeError && "Data type is required"}</FormHelperText>
									</FormControl>
								</Grid>
							)}
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<FormControl>
								<FormControlLabel
									label="Encrypt"
									control={
										<Checkbox
											checked={fieldDetails?.encryption_flg === 1}
											onChange={(e) => {
												setFieldDetails((prev) => ({
													...prev,
													encryption_flg: e.target.checked ? 1 : 0,
												}));
											}}
										/>
									}
									/>
									<FormHelperText>
										Does the field need to be encrypted?
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<FormControl>

									<FormControlLabel
										label="Required"
										control={
											<Checkbox
												checked={fieldDetails?.required_flg === 1}
												onChange={(e) => {
													setFieldDetails((prev) => ({
														...prev,
														required_flg: e.target.checked ? 1 : 0,
													}));
												}}
											/>
										}
									/>
									<FormHelperText>
										Is this field required for authentication?
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<FormControl>
									<FormControlLabel
										label="Connector Specific Field"
										control={
											<Checkbox
												checked={fieldDetails?.connector_specific_flg === 1}
												onChange={(e) => {
													setFieldDetails((prev) => ({
														...prev,
														connector_specific_flg: e.target.checked ? 1 : 0,
													}));
												}}
											/>
										}
									/>
									<FormHelperText>
										Is this field specific to the connector?
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>

					</AlertDialog>

					{showAuthTypeAddModal && (
						<AuthTypeAddModal
							showAuthTypeAddModal={showAuthTypeAddModal}
							setShowAuthTypeAddModal={setShowAuthTypeAddModal}
							handleAuthTypeChange={handleAuthTypeChange}
						/>
					)}
				</>
			)}
		</>
	);
};

export default Authentication;
