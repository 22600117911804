import { TextField, RadioGroup, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material'
import { Controller } from 'react-hook-form'
import InputAdornment from '@mui/material/InputAdornment'

export function DataConTextField({ name, control, label, placeholder, rules = {}, helperText, endAdornment, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <TextField
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          value={value}
          InputProps={{
            endAdornment: <InputAdornment position="end">{endAdornment || ""}</InputAdornment>,
          }}
          label={label}
          placeholder={placeholder}
          {...rest}
          error={!!error}
          helperText={error ? error.message : helperText}

        />
      )}
      rules={rules}
    />
  )
}

export function DataConSelect({ name, control, label, rules = {}, helperText, options, optionKey, optionValue, onChange, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={options[0]}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <FormControl fullWidth size="small">
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={value}
            onChange={onChange}
          >
            {optionKey ? options.map((option) => (
              <MenuItem key={option[optionKey]} value={option[optionKey]}>
                {option[optionValue]}
              </MenuItem>
            )) : options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={error}>{error ? error.message : helperText}</FormHelperText>
        </FormControl>

      )}
      rules={rules}

    />
  )
}

export function DataConRadio({ name, control, label, placeholder, rules = {}, helperText, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <RadioGroup
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          value={value}
          {...rest}
          error={!!error}
          helperText={error ? error.message : helperText}
        />
      )}
      rules={rules}
    />
  )
}

export function DataConCheckbox({ name, control, label, rules = {}, helperText, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={false}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl>
          <FormControlLabel
            name={name}
            control={(
              <Checkbox
                name={name}
                color="primary"
                checked={value}
                onChange={onChange}
              />
            )}
            label={label}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    />

  )
}